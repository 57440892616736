import React, { Component, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class High extends Component {
    
      
    render() {
        const series2 = this.props.data;

        const options = {
            credits: { enabled: false },
            tooltip: {
                enabled: false // 말풍선 비활성화
            },
            chart: { height: 285, animation: false }, //높이 258 고정 최대 높이임
            title:null,
            plotOptions: {
                pie: {
                dataLabels: {
                    enabled: true,
                    // format: '{point.y}',
                    formatter: function () {
                        // 여기서 this.point.y는 데이터 포인트의 값입니다.
                        return Highcharts.numberFormat(this.point.y, 0, ',', ',');
                      },
                    distance: -25,
                    style: { fontSize: '13px', textOutline: 0 },
                },
                animation: false,
                showInLegend: true,
                },
            },
            legend: {
                layout: 'vertical',
                verticalAlign: 'middle',
                align: 'right',
                symbolHeight: 8,
                symbolRadius: 5,
                itemMarginTop: 0,
                itemMarginBottom: 10,
            },
            series: [
            {
            type: 'pie',
            innerSize: '0',
           
            data: series2,
            },
            ],

        }
        return (
            <Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={this.chartRef} // 차트 컴포넌트에 ref 추가
          />
      </Fragment>
        );
    }
}
export default High;