import React, { useEffect, useState, useCallback } from "react";
import { Axios_get, Axios_post } from "./external_module";
import PagingComponent from "../basic/PagingComponent";
import { useLocation } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import Loding from "../basic/Loding";
function Visitor() {
  const url = "web/detail/visitor";
  // const url_excel = 'web/detail/visitor/excel';

  const url_Option = "web/detail/cont";
  // 데이터 로드 및 페이징 관련 변수
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedGender, setSelectedGender] = useState("all");
  const [pickDate, setPickDate] = useState();
  const [pickDate2, setPickDate2] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedAge, setSelectedAge] = useState([
    "10",
    "20",
    "30",
    "40",
    "50",
    "60",
    "70",
    "80",
    "90",
  ]);
  const [getpoststatus, setgetpoststatus] = useState(0);
  const [checkboxState, setCheckboxState] = useState({
    ageall: true,
    age10: false,
    age20: false,
    age30: false,
    age40: false,
    age50: false,
    age60: false,
    age70: false,
    age80: false,
    age90: false,
  });
  const [visitorData, setNoticeData] = useState([]);
  const [noneContinent, setNoneContinent] = useState(true);
  const [saveContinent, setSaveContinent] = useState();
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState({
    page: 1,
    recordSize: 14,
    pageSize: 5,
  });
  const [continent, setContinent] = useState([]);
  const [country, setCountry] = useState([]);
  const [country_number, setCountry_number] = useState("");
  const [acc_name, setAcc_name] = useState("");
  const url_paging = `web/detail/visitor?page=${params.page}&recordSize=${params.recordSize}&pageSize=5`;
  console.log("country_number", country_number);
  console.log("getpoststatus", getpoststatus);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const regionOption = [
    { label: "Phongsali", value: "Phongsali" },
    { label: "LouangNamtha", value: "LouangNamtha" },
    { label: "Bokeo", value: "Bokeo" },
    { label: "Oudomxai", value: "Oudomxai" },
    { label: "Sainyabuli", value: "Sainyabuli" },
    { label: "LouangPrabang", value: "LouangPrabang" },
    { label: "Houaphan", value: "Houaphan" },
    { label: "Xianghoang", value: "Xianghoang" },
    { label: "VientianeProvince", value: "VientianeProvince" },
    { label: "Xaysomboun", value: "Xaysomboun" },
    { label: "VientianePrefecture", value: "VientianePrefecture" },
    { label: "Bolikhamxai", value: "Bolikhamxai" },
    { label: "Khammouan", value: "Khammouan" },
    { label: "Savannakhet", value: "Savannakhet" },
    { label: "Salavan", value: "Salavan" },
    { label: "Champasak", value: "Champasak" },
    { label: "Sekong", value: "Sekong" },
    { label: "Attapu", value: "Attapu" },
  ];

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    handleAgeChange(name, checked);
  };
  const handleAgeChange = (value, isChecked) => {
    let updatedCheckboxState = { ...checkboxState };

    if (value === "ageall") {
      updatedCheckboxState = {
        ageall: true,
        age10: false,
        age20: false,
        age30: false,
        age40: false,
        age50: false,
        age60: false,
        age70: false,
        age80: false,
        age90: false,
      };
    } else {
      updatedCheckboxState[value] = isChecked;
      updatedCheckboxState.ageall = false;
    }

    const updatedListAge = [];

    if (updatedCheckboxState.ageall) {
      updatedListAge.push("10", "20", "30", "40", "50", "60", "70", "80", "90");
    } else {
      if (updatedCheckboxState.age10) updatedListAge.push("10");
      if (updatedCheckboxState.age20) updatedListAge.push("20");
      if (updatedCheckboxState.age30) updatedListAge.push("30");
      if (updatedCheckboxState.age40) updatedListAge.push("40");
      if (updatedCheckboxState.age50) updatedListAge.push("50");
      if (updatedCheckboxState.age60) updatedListAge.push("60");
      if (updatedCheckboxState.age70) updatedListAge.push("70");
      if (updatedCheckboxState.age80) updatedListAge.push("80");
      if (updatedCheckboxState.age90) updatedListAge.push("90");
    }

    setCheckboxState(updatedCheckboxState);
    setSelectedAge(updatedListAge);
  };
  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };
  const openPopup = () => {
    setgetpoststatus(2);

    setIsPopupOpen(true);
    Axios_get(url_Option).then((result) => {
      if (result !== null) {
        console.log("result_option data", result);
        setContinent(result);
      } else {
        console.log("오류가 발생했습니다.");
      }
    });
  };
  let context;

  console.log(context);
  const Option_Check_OK = () => {
    setIsPopupOpen(false);
  };
  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const fetchData = async () => {
    console.log("getpoststatus", getpoststatus);
    if (getpoststatus === 0) {
      Axios_get(url, params).then((result) => {
        if (result !== null) {
          console.log("get result ", result);

          // 서버로부터 받아온 값 SET
          setNoticeData(result.list);
          setPagination(result.pagination);
        } else {
          console.log("오류가 발생했습니다.");
        }
      });
    } else if (getpoststatus === 2 || getpoststatus === 1) {
      if (noneContinent === true) {
        context = {
          sex: selectedGender,
          ageGroup: selectedAge,
          acc: acc_name,
          startDatetime: pickDate,
          endDatetime: pickDate2,
          name: searchKeyword,
        };
      } else {
        context = {
          sex: selectedGender,
          country: country_number,
          ageGroup: selectedAge,
          acc: acc_name,
          startDatetime: pickDate,
          endDatetime: pickDate2,
          name: searchKeyword,
        };
      }
      console.log("option context", context);

      Axios_post(url_paging, context).then((result) => {
        if (result !== null) {
          console.log("post_result", result);
          setNoticeData(result.list);
          setPagination(result.pagination);
          console.log("result.pagination", result.pagination);
        } else {
          console.log("오류가 발생했습니다.");
        }
      });
    }
    console.log("noneContinent", noneContinent);
  };

  useEffect(() => {
    fetchData();
  }, [
    params.page,
    getpoststatus,
    country_number,
    selectedGender,
    selectedAge,
    searchKeyword,
    acc_name,
    pickDate,
    pickDate2,
    noneContinent,
  ]);
  {
    /*
        페이지 번호 클릭 및 해당 페이지로 이동
     */
  }
  const movePage = useCallback(
    async (page) => {
      setParams({ ...params, page });
      console.log("PageNumber >> ", page);
    },
    [params, pagination]
  );

  {
    /*
       방문객 사진 클릭 시 사진 확대 모달
    */
  }
  const [modalStates, setModalStates] = useState(visitorData.map(() => false));

  const openModal = (visitorId, index) => {
    console.log("visitorId !!", visitorId);
    const data = {
      CMD: "customer_photo",
      id: visitorId,
    };

    newSocket.send(JSON.stringify(data));

    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = true;
    console.log("updatedModalStates", updatedModalStates);
    setModalStates(updatedModalStates);
  };

  const closeModal = (index) => {
    const updatedModalStates = [...modalStates];
    updatedModalStates[index] = false;
    setModalStates(updatedModalStates);
    setModalImageData(null);
  };

  // WebSocket setup
  const [socket, setSocket] = useState(null);
  const [modalImageData, setModalImageData] = useState(null);

  const newSocket = new WebSocket(
    `wss://laosapi.smsoft.co.kr/app/socketnotice`
  );

  useEffect(() => {
    newSocket.onopen = (event) => {
      console.log("WebSocket connection opened:", event);
      const data = {
        CMD: "LOGIN",
        id: "admin",
      };
      newSocket.send(JSON.stringify(data));
    };

    newSocket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        console.log("WebSocket message received:", data.image);
        const decodedImage = decodeBase64Image(data.image);
        console.log("decodedImage", decodedImage);
        setModalImageData(decodedImage);
      } catch (error) {
        console.error("Error parsing WebSocket message:", error);
      }
    };

    newSocket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
    };
    newSocket.onerror = (error) => {
      console.error("WebSocket errora:", error);
      // Handle the error as needed, e.g., display a message to the user
    };
    setSocket(newSocket);
    // Cleanup function to close the WebSocket on component unmount
    return () => {
      newSocket.close();
    };
  }, []); // Run only once when the component mounts

  const handleSearch = async () => {
    setgetpoststatus(1);
  };
  const handleKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
    setgetpoststatus(1);
  };
  {
    /*
        서버로부터 인코딩되어있는 여권 사진 정보를 받아.
        디코딩해서 화면에 출력
    */
  }
  const handleOptionChangecountry = (e) => {
    setCountry_number(Number(e.target.value));
  };
  const handleOptionChange = (e) => {
    if (e.target.value === "none") {
      setNoneContinent(true);
    } else {
      setNoneContinent(false);
    }
    setSaveContinent(Number(e.target.value));
    const context = {
      cont_id: Number(e.target.value),
    };
    Axios_post(url_Option, context).then((result) => {
      if (result !== null) {
        setCountry(result);
        if (result.length !== 0) {
          setCountry_number(result[0]["country_id"]);
        }
      } else {
        console.log("오류가 발생했습니다.");
      }
    });
    //여기서 post사용 selectedOption
  };

  const decodeBase64Image = (base64ImageData) => {
    const decodedImageData = atob(base64ImageData);

    // 디코딩된 데이터를 Uint8Array로 변환
    const uint8Array = new Uint8Array(decodedImageData.length);

    for (let i = 0; i < decodedImageData.length; i++) {
      uint8Array[i] = decodedImageData.charCodeAt(i);
    }

    // Blob 생성
    const blob = new Blob([uint8Array], { type: "image/jpeg" });

    return URL.createObjectURL(blob);
  };

  /* 언어변경 */
  const [translatedText, setTranslatedText] = useState({});
  const [cookies] = useCookies(["sessionID", "userGrade", "language"]);

  const language = cookies.language;
  const country_rendering = (language) => {
    const country_html = [];

    if (noneContinent === true) {
      if (language === "1") {
        country_html.push(<option value={0}>SELET A Country</option>);
      } else {
        country_html.push(<option value={0}>ເລືອກປະເທດ</option>);
      }
    }

    for (let i = 0; i < country.length; i++) {
      if (language === "1") {
        country_html.push(
          <option value={country[i]["country_id"]}>
            {country[i]["country_name"]}
          </option>
        );
      } else {
        if (country[i]["country_name"] === "Ghana") {
          country[i]["country_name"] = "ການາ";
        }
        if (country[i]["country_name"] === "Nigeria") {
          country[i]["country_name"] = "ໄນຈີເຣຍ";
        }
        if (country[i]["country_name"] === "Korea") {
          country[i]["country_name"] = "ເກົາຫຼີ";
        }
        if (country[i]["country_name"] === "Laos") {
          country[i]["country_name"] = "ປະເທດລາວ";
        }
        if (country[i]["country_name"] === "NewZealand") {
          country[i]["country_name"] = "ນິວ​ຊີ​ແລນ";
        }
        if (country[i]["country_name"] === "Australia") {
          country[i]["country_name"] = "ອອສເຕຣເລຍ";
        }
        if (country[i]["country_name"] === "Germany") {
          country[i]["country_name"] = "ເຢຍລະມັນ";
        }
        if (country[i]["country_name"] === "Spain") {
          country[i]["country_name"] = "ສະເປນ";
        }
        if (country[i]["country_name"] === "USA") {
          country[i]["country_name"] = "nອາ​ເມລິ​ກາ";
        }
        if (country[i]["country_name"] === "Canada") {
          country[i]["country_name"] = "ການາດາ";
        }
        if (country[i]["country_name"] === "Brazil") {
          country[i]["country_name"] = "ບຣາຊິນ";
        }
        if (country[i]["country_name"] === "Argentina") {
          country[i]["country_name"] = "ອາເຈນຕິນາ";
        }

        country_html.push(
          <option value={country[i]["country_id"]}>
            {country[i]["country_name"]}
          </option>
        );
      }
    }

    return country_html;
  };
  const continent_rendering = (language) => {
    const continent_html = [];
    if (language === "1") {
      continent_html.push(<option value="none">SELET A Continent</option>);
    } else {
      continent_html.push(<option value="none">ເລືອກທະວີບ</option>);
    }
    for (let i = 0; i < continent.length; i++) {
      if (language === "1") {
        continent_html.push(
          <option value={continent[i]["cont_id"]}>
            {continent[i]["cont_name"]}
          </option>
        );
      } else {
        if (continent[i]["cont_name"] === "Africa") {
          continent[i]["cont_name"] = "ອາຟຣິກາ";
        } else if (continent[i]["cont_name"] === "Antarctica") {
          continent[i]["cont_name"] = "ແອນຕາກຕິກ";
        } else if (continent[i]["cont_name"] === "Asia") {
          continent[i]["cont_name"] = "ອາຊີ";
        } else if (continent[i]["cont_name"] === "Oceania") {
          continent[i]["cont_name"] = "ໂອເຊຍເນຍ";
        } else if (continent[i]["cont_name"] === "Europe") {
          continent[i]["cont_name"] = "ເອີຣົບ";
        } else if (continent[i]["cont_name"] === "North America") {
          continent[i]["cont_name"] = "ອາ​ເມລິ​ກາ​ເຫນືອ";
        } else if (continent[i]["cont_name"] === "South America") {
          continent[i]["cont_name"] = "ອາ​ເມລິ​ກາ​ໃຕ້";
        }

        continent_html.push(
          <option value={continent[i]["cont_id"]}>
            {continent[i]["cont_name"]}
          </option>
        );
      }
    }
    return continent_html;
  };
  let genderOptions = [
    { label: "All", value: "all" },
    { label: "Man", value: "Man" },
    { label: "Woman", value: "Female" },
  ];

  if (language === "2") {
    genderOptions = [
      { label: "ໃຜໆກໍ", value: "all" },
      { label: "ຊາຍ", value: "Man" },
      { label: "ສະຕຣີ", value: "Female" },
    ];
  }
  useEffect(() => {
    if (language === "2") {
      const translatedText = {
        "Please search your hotel name": "ກະລຸນາຄົ້ນຫາຊື່ໂຮງແຮມຂອງທ່ານ",
        "Please search your name": "ກະລຸນາຄົ້ນຫາຊື່ຂອງເຈົ້າ",
        ALL: "ໃຜໆກໍ",
        Companion: "ສະຫາຍ",
        "check out": "ເຊັກເອົາ",
        "check in": "ເຊັກອິນ",
        hotel: "ໂຮງແຮມ",
        country: "ບ່ອນຕ່າງໆ",
        birth: "ວັນເດືອນປີເກີດ",
        gender: "ເພດ",
        name: "ຊື່",
        photo: "ຮູບຖ່າຍ",
        no: "ເລກ",
        Visitor: "ນັກທ່ອງທ່ຽວ",
        Search: "ຊອກຫາ",
        Close: "ປິດ",
        OK: "ຕົກ​ລົງ",
        "Hotel name": "ຊື່ໂຮງແຮມ",
        continent: "ທະວີບ",
        "Country lookup by continent": "ການຊອກຫາປະເທດຕາມທະວີບ",
        AGE: "ອາຍຸ",
        GENDER: "ກ",
        "DETAIL OPTION": "ຕົວເລືອກລາຍລະອຽດ",
        "Check In": "ເຊັກອິນ",
      };
      setTranslatedText(translatedText);
    } else {
      setTranslatedText({});
    }
  }, [language]);

  const rendering = () => {
    const html = [];
    console.log("visitorData", visitorData);
    for (let i = 0; i < visitorData.length; i++) {
      let country = visitorData[i]["country_name"];
      if (language === "1") {
        country = visitorData[i]["country_name"];
      } else {
        country = visitorData[i]["country_name"];
        if (country === "Ghana") {
          country = "ການາ";
        }
        if (country === "Nigeria") {
          country = "ໄນຈີເຣຍ";
        }
        if (country === "Korea") {
          country = "ເກົາຫຼີ";
        }
        if (country === "Laos") {
          country = "ປະເທດລາວ";
        }
        if (country === "NewZealand") {
          country = "ນິວ​ຊີ​ແລນ";
        }
        if (country === "Australia") {
          country = "ອອສເຕຣເລຍ";
        }
        if (country === "Germany") {
          country = "ເຢຍລະມັນ";
        }
        if (country === "Spain") {
          country = "ສະເປນ";
        }
        if (country === "USA") {
          country = "nອາ​ເມລິ​ກາ";
        }
        if (country === "Canada") {
          country = "ການາດາ";
        }
        if (country === "Brazil") {
          country = "ບຣາຊິນ";
        }
        if (country === "Argentina") {
          country = "ອາເຈນຕິນາ";
        }
      }
      const photo_value = visitorData[i]["photo"];
      let decodedImage = null;
      let photoClassName = "photo_td_none";
      if (photo_value) {
        try {
          decodedImage = decodeBase64Image(photo_value);
          photoClassName = "photo_td";
        } catch (error) {
          // console.error('Error decoding image:', error);   //에러 확인 현재는 인코딩 안되어있는 애들만 에러뜰거임 로그 더러워져서 주석 서버에서 이미지 파일 없이 보낸 경우에 어떻게 처리할지도 생각해야할듯
        }
      }
      html.push(
        <tr key={visitorData[i]["visitor_id"]}>
          <th>{visitorData[i]["visitor_id"]}</th>
          <td /* 사진 없을경우  className='photo_td_none' */>
            <button
              id="modalOpenButton"
              onClick={() => openModal(visitorData[i].visitor_id, i)}
            >
              {" "}
              <img src="../images/photo_icon.png" alt="photo" />{" "}
            </button>

            {modalStates[i] && (
              <div id="modalContainer" className="modal">
                <div className="modal-content">
                  <button id="modalCloseButton" onClick={() => closeModal(i)}>
                    CLOSE
                  </button>
                  {/* <p>{ visitorData[i]['photo'] }</p> */}
                  <div className="imgbox">
                    {modalImageData ? (
                      <img src={modalImageData} alt="Visitor" />
                    ) : (
                      <Loding />
                    )}
                  </div>
                </div>
              </div>
            )}
          </td>
          <td>{visitorData[i]["name"]}</td>
          <td>{visitorData[i]["sex"]}</td>
          <td>{visitorData[i]["birth"]}</td>
          <td>{country}</td>
          <td>{visitorData[i]["acc_name"]}</td>
          <td>{visitorData[i]["check_in"]}</td>
          <td>{visitorData[i]["check_out"]}</td>
          {/* <td>{visitorData[i]['accompany_num']}</td> */}
        </tr>
      );
    }
    return html;
  };

  return (
    <div>
      <div className="sub_wrp notice detail_box">
        <button
          onClick={openPopup}
          style={{ position: "absolute" }}
          className="detailbtn"
        >
          {translatedText["DETAIL OPTION"] || "DETAIL OPTION"}
        </button>
        {isPopupOpen && (
          <div className="popup">
            <div className="popup-content">
              <div className="tbwrp">
                <h4>{translatedText["DETAIL OPTION"] || "DETAIL OPTION"}</h4>
                <table>
                  <tbody>
                    <tr>
                      <th>{translatedText["GENDER"] || "GENDER"}</th>
                      <td className="gen_td">
                        <div>
                          {genderOptions.map((option) => (
                            <div key={option.value}>
                              <input
                                type="radio"
                                name="gender"
                                id={option.value}
                                value={option.value}
                                onChange={handleGenderChange}
                                checked={selectedGender === option.value}
                              />
                              <label htmlFor={option.value}>
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{translatedText["AGE"] || "AGE"}</th>
                      <td className="age_td">
                        <div>
                          <input
                            type="checkbox"
                            name="ageall"
                            id="ageall"
                            value="ageall"
                            checked={checkboxState.ageall}
                            onChange={handleCheckboxChange}
                          />
                          <label For="ageall">
                            {translatedText["ALL"] || "ALL"}
                          </label>
                          <input
                            type="checkbox"
                            name="age10"
                            id="age10"
                            value="age10"
                            checked={checkboxState.age10}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age10">10's</label>
                          <input
                            type="checkbox"
                            name="age20"
                            id="age20"
                            value="age20"
                            checked={checkboxState.age20}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age20">20's</label>
                          <input
                            type="checkbox"
                            name="age30"
                            id="age30"
                            value="age30"
                            checked={checkboxState.age30}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age30">30's</label>
                          <input
                            type="checkbox"
                            name="age40"
                            id="age40"
                            value="age40"
                            checked={checkboxState.age40}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age40">40's</label>
                          <input
                            type="checkbox"
                            name="age50"
                            id="age50"
                            value="age50"
                            checked={checkboxState.age50}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age50">50's</label>
                          <input
                            type="checkbox"
                            name="age60"
                            id="age60"
                            value="age60"
                            checked={checkboxState.age60}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age60">60's</label>
                          <input
                            type="checkbox"
                            name="age70"
                            id="age70"
                            value="age70"
                            checked={checkboxState.age70}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age70">70's</label>
                          <input
                            type="checkbox"
                            name="age80"
                            id="age80"
                            value="age80"
                            checked={checkboxState.age80}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age80">80's</label>
                          <input
                            type="checkbox"
                            name="age90"
                            id="age90"
                            value="age90"
                            checked={checkboxState.age90}
                            onChange={handleCheckboxChange}
                          />
                          <label For="age90">90's</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {translatedText["Country lookup by continent"] ||
                          "Country lookup by continent"}
                      </th>
                      <td>
                        <div className="cc_td">
                          <p>{translatedText["continent"] || "continent"}</p>
                          <select
                            name=""
                            id=""
                            onChange={handleOptionChange}
                            value={saveContinent}
                          >
                            {continent_rendering(language)}
                          </select>
                          <p>{translatedText["country"] || "country"}</p>
                          <select
                            name=""
                            id=""
                            onChange={handleOptionChangecountry}
                            value={country_number.toString()}
                          >
                            {country_rendering(language)}
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{translatedText["Hotel name"] || "Hotel name"}</th>
                      <td>
                        <input
                          type="text"
                          value={acc_name}
                          onChange={(e) => setAcc_name(e.target.value)}
                          placeholder={
                            translatedText["Please search your hotel name"] ||
                            "Please search your hotel name"
                          }
                          className="sertd"
                        ></input>
                      </td>
                    </tr>
                    <tr>
                      <th>{translatedText["Check In"] || "Check In"}</th>
                      <td>
                        <div className="datetd">
                          <input
                            type="date"
                            id="pickdate"
                            value={pickDate}
                            onChange={(e) => setPickDate(e.target.value)}
                          />
                          <input
                            type="date"
                            id="pickdate2"
                            value={pickDate2}
                            onChange={(e) => setPickDate2(e.target.value)}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="btnwrp">
                <button onClick={closePopup} className="col02">
                  {translatedText["Close"] || "Close"}
                </button>
                <button onClick={Option_Check_OK} className="col01">
                  {translatedText["OK"] || "OK"}{" "}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="search_box detail_sub_seach">
          <form>
            <input
              type="search"
              name="searchKeyword"
              placeholder={
                translatedText["Please search your name"] ||
                "Please search your name"
              }
              value={searchKeyword}
              onChange={handleKeywordChange}
            />
          </form>
          <button onClick={handleSearch}>
            {translatedText["Search"] || "Search"}
          </button>
        </div>
        <h4>{translatedText["Visitor"] || "Visitor"}</h4>
        <div>
          <div className="tbwrp">
            <table>
              <colgroup>
                <col width="5%" />
                <col width="5%" />
                <col width="16%" />
                <col width="6%" />
                <col width="9%" />
                <col width="8%" />
                <col width="*%" />
                <col width="10%" />
                <col width="10%" />
                {/* <col width='8%' /> */}
              </colgroup>
              <thead>
                <tr>
                  <th>{translatedText["no"] || "no"}</th>
                  <th>{translatedText["photo"] || "photo"}</th>
                  <th>{translatedText["name"] || "name"}</th>
                  <th>{translatedText["gender"] || "gender"}</th>
                  <th>{translatedText["birth"] || "birth"}</th>
                  <th>{translatedText["country"] || "country"}</th>
                  <th>{translatedText["hotel"] || "hotel"}</th>
                  <th>{translatedText["check in"] || "check in"}</th>
                  <th>{translatedText["check out"] || "check out"}</th>
                  {/* <th>{translatedText['Companion'] || 'Companion'}</th> */}
                </tr>
              </thead>
              <tbody>{rendering()}</tbody>
            </table>
          </div>
          <div className="pasing paging">
            <PagingComponent
              pagination={pagination}
              params={params}
              onPageChange={movePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Visitor;
