import React, { useEffect, useState, useCallback } from 'react';
import { Axios_get } from './external_module';
import { Link } from 'react-router-dom';
import PagingComponent from '../basic/PagingComponent';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function NoticeList({handleItemClick}) {
    useEffect(() => {
        handleItemClick('lst04')
        
      }, [handleItemClick]);
    const url = 'web/notice';

    const [noticeData, setNoticeData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [params, setParams] = useState({ page: 1, recordSize: 14, pageSize: 5 });
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const language = cookies.language; 
    
    
    {/*
        [] 안에있는 값이 바뀔 때마다
        findAllPost() 데이터 Reload
     */}
    useEffect(() => {
        findAllPost();
    }, [params.page]);


    {/* 
        서버에 데이터 & 페이지 정보 요청
    */}
    const findAllPost = async () => {

        Axios_get( url, params ).then((result) => {

            if (result !== null) {
                console.log('>> DATA ', result.list);
                // 서버로부터 받아온 값 SET
                setNoticeData(result.list);
                setPagination(result.pagination);
            } else {
                console.log('오류가 발생했습니다.');
            }
        });
    };


    {/*
        페이지 번호 클릭 및 해당 페이지로 이동
                                                                     
        - endPage : 페이지 그룹의 맨 뒷 숫자
        - totalPageCount : 총 만들어지는 페이지의 개수
     */}
    const movePage = useCallback( async ( page ) => {
        //setParams({ ...params, page });
        setParams(prevParams => ({ ...prevParams, page }));
    }, [params, pagination]);    

    /* 언어변경 */
    const [translatedText, setTranslatedText] = useState({});

    useEffect(() => {
        if (language === '2') {
        const translatedText = {
            'no': 'ເລກ',
            'Notice list':'ລາຍ​ການ​ແຈ້ງ​ການ​',
            'Category':'ປະເພດ',
            'Subject':'ວິຊາ',
            'File':'ໄຟລ໌',
            'CreateDate':'ສ້າງວັນທີ',
            'Writer':'ນັກຂຽນ',
            'Views':'ທັດສະນະ',
            'ADD':'ເພີ່ມຕື່ມ2',
            'DEVICE NOTICE': 'ແຈ້ງການອຸປະກອນ',
            'ADMIN NOTICE':'ແຈ້ງການຜູ້ບໍລິຫານ',
            'POST':'ໂພສ'
        };
        setTranslatedText(translatedText);
        } else {
        setTranslatedText({});
        }
    }, [language]);


    const rendering = () => {
        const html = [];
        console.log('noticeData',noticeData)
        for (let i = 0; i < noticeData.length; i++) {
            const writeDate = new Date(noticeData[i]['write_date']).toLocaleString('ko-KR');

            const index = ( params.page - 1 ) * params.recordSize + 1;
            const postLink = `/notice/${noticeData[i]['notice_id']}`;

            html.push(
                <tr key={index + i} className={noticeData[i]['category_id_id']===2?'noticeLine':''} onClick={() => window.location.href = postLink }  /* 공지사항일 경우 className='noticeLine' */>
                    <th>{noticeData[i]['notice_id']}</th>
                   <td>
                    {
                        noticeData[i]['category_id_id'] === 1
                        ? translatedText['DEVICE NOTICE'] || 'DEVICE NOTICE'
                        : noticeData[i]['category_id_id'] === 2
                        ? translatedText['ADMIN NOTICE'] || 'ADMIN NOTICE'
                        : translatedText['POST'] || 'POST'
                    }
                   </td>
                   <td>{noticeData[i]['title']}</td>
                    <td>
                        { noticeData[i]['file'] !== null ? (
                            <span className='filetd file_icon01' /* 파일이 있을 경우 */></span>
                        ):(
                            <span className='filetd file_icon02' /* 파일이 없을 경우 */></span>
                        )}
                    </td>
                    <td>{noticeData[i]['auth_user_name']}</td>
                    <td>{writeDate}</td>
                    <td>{noticeData[i]['view_count']}</td>
                </tr>
            );
        }
        return html;
    };

    return (
        <div>
            <div className="sub_wrp notice noticelist_page">
                {/* <Notice /> */}
                <h4>{translatedText['Notice list'] || 'Notice list'}</h4>
                <div>
                    <div className="tbwrp">
                        <table>
                            <colgroup>
                                <col width='4%' />
                                <col width='7%' />
                                <col width='33%' />
                                <col width='5%' />
                                <col width='9%' />
                                <col width='9%' />
                                <col width='4%' />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{translatedText['no'] || 'no'}</th>
                                    <th>{translatedText['Category'] || 'Category'}</th>
                                    <th>{translatedText['Subject'] || 'Subject'}</th>
                                    <th>{translatedText['File'] || 'File'}</th>
                                    <th>{translatedText['Writer'] || 'Writer'}</th>
                                    <th>{translatedText['CreateDate'] || 'CreateDate'}</th>
                                    <th>{translatedText['Views'] || 'Views'}</th>
                                </tr>
                            </thead>
                            <tbody>{ rendering() }</tbody>
                        </table>
                    </div>
                    <div className='pasing'>
                        <div className="paging">
                            <PagingComponent
                                pagination = { pagination }
                                params = { params }
                                onPageChange = { movePage }
                            />    
                        </div>
                    </div>
                    <div className='btnwrp'>
                        <button className='col01'>
                            <Link to="/notice">{translatedText['ADD'] || 'ADD'}</Link>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default NoticeList;
