import React, { useEffect, useState, useCallback,useRef  } from 'react';
import { Axios_get, Axios_post } from './external_module';
import PagingComponent from '../basic/PagingComponent';
import { useLocation } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';

function Hotel ()  {
    const selectRef = useRef(null);
    const url = 'web/detail/hotel';
    const [params, setParams] = useState({ page: 1, recordSize: 14, pageSize: 5 });

    const url_paging = `web/detail/hotel?page=${params.page}&recordSize=${params.recordSize}&PageSize=${params.pageSize}`;
    const [visitorData, setNoticeData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedRegion, setSelectedRegion] = useState(undefined);
    const [getpoststatus,setgetpoststatus] = useState(0);

    
    const handleRegionChange = (event) => {
        setgetpoststatus(1)
        const selectedValue = event.target.value;
        if (selectedValue === "none") {
            setSelectedRegion(undefined);
        } else {
            setSelectedRegion(selectedValue);
        }
        
    };

    const handleKeywordChange = (e) => {
        setgetpoststatus(1)
        setSearchKeyword(e.target.value);
        
    };
    useEffect(() => {
        console.log('getpoststatus',getpoststatus)
        console.log('selectRef',selectRef.current.value)
        if(selectRef.current.value === 'none' && searchKeyword === ''){

            Axios_get( url, params ).then((result) => {
                if (result !== null) {
                    console.log(result);
    
                    setNoticeData(result.list);
                    setPagination(result.pagination);
    
                } else {
                    console.log('오류가 발생했습니다.');
                }
            });
        }
        else {
            let context
            if (selectRef.current.value === 'none'){    //select box값이 none인 경우 text값만 요청
                context = {
                    'acc' : searchKeyword,
                }
            }
            else{                                       //select box값이 none이 아닌경우 text값이랑 지역 value값으로 요청
                context = {
                        'acc' : searchKeyword,
                        region: selectRef.current.value
                    } 
            }
            // const context = {
            //     'acc' : searchKeyword,
            //     region: selectRef.current.value
            // }
            console.log("context",context)
            Axios_post( url_paging, context ).then((result) => {
                if (result !== null) {
                    console.log("post_result",result);
                    setNoticeData(result.list);
                    setPagination(result.pagination);
    
                } else {
                    console.log('오류가 발생했습니다.');
                }
            });
        }
        
    }, [params.page,getpoststatus,selectedRegion,searchKeyword,selectRef]);
    const handleSearch = async () => {
        setgetpoststatus(1)
    };
    {/*
        페이지 번호 클릭 및 해당 페이지로 이동

        > Paging 관련변수
        - startPage : 페이지 그룹의 맨 앞 숫자
        - endPage : 페이지 그룹의 맨 뒷 숫자
        - totalPageCount : 총 만들어지는 페이지의 개수
     */}
     const movePage = useCallback( async ( page ) => {
        setParams({ ...params, page });
    }, [params, pagination]);    

    /* 언어변경 */
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
    const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);
    const [translatedText, setTranslatedText] = useState({});
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const language = cookies.language;
    useEffect(() => {
        if (language === '2') {
        const translatedText = {
            'Please search your name' : 'ກະລຸນາຄົ້ນຫາຊື່ຂອງເຈົ້າ',
            'Business Number': 'ໝາຍເລກທຸລະກິດ',
            'rooms': 'ຫ້ອງ',
            'Rating': 'ຄະແນນ',
            'devices': 'ອຸປະກອນ',
            'Search': 'ຊອກຫາ',
            'SELECT A REGION' : 'ເລືອກພາກພື້ນ',
            'tel number': 'ເບີໂທ',
            'address': 'ທີ່ຢູ່',
            'name': 'ຊື່',
            'no': 'ເລກ',
            'hotel':'ໂຮງແຮມ',
            'Go to detail page when clicked':'ໄປທີ່ໜ້າລາຍລະອຽດເມື່ອຄລິກ'
        };
        setTranslatedText(translatedText);
        } else {
        setTranslatedText({});
        }
    }, [language]);
    let regionOption
    console.log("language여기도 잘 나오니?",language)
    if(language === '1'){
        regionOption = [
            { label: 'Phongsali', value: 'Phongsali' },
            { label: 'LouangNamtha', value: 'LouangNamtha' },
            { label: 'Bokeo', value: 'Bokeo' },
            { label: 'Oudomxai', value: 'Oudomxai' },
            { label: 'Sainyabuli', value: 'Sainyabuli' },
            { label: 'LouangPrabang', value: 'LouangPrabang' },
            { label: 'Houaphan', value: 'Houaphan' },
            { label: 'Xianghoang', value: 'Xianghoang' },
            { label: 'Vientiane', value: 'Vientiane' },
            { label: 'Xaysomboun', value: 'Xaysomboun' },
            { label: 'VientianPrefecture', value: 'VientianPrefecture' },
            { label: 'Bolikhamxai', value: 'Bolikhamxai' },
            { label: 'Khammouan', value: 'Khammouan' },
            { label: 'Savannakhet', value: 'Savannakhet' },
            { label: 'Salavan', value: 'Salavan' },
            { label: 'Champasak', value: 'Champasak' },
            { label: 'Sekong', value: 'Sekong' },
            { label: 'Attapu', value: 'Attapu' }
        ];
    }
    else if(language === '2'){
        regionOption = [
            { label: 'ຜົ້ງສາລີ', value: 'Phongsali' },
            { label: 'ຫຼວງນ້ຳທາ', value: 'LouangNamtha' },
            { label: 'ບໍ່ແກ້ວ', value: 'Bokeo' },
            { label: 'ອຸດົມໄຊ', value: 'Oudomxai' },
            { label: 'ໄຊຍາບູລີ', value: 'Sainyabuli' },
            { label: 'ຫຼວງ​ພະ​ບາງ', value: 'LouangPrabang' },
            { label: 'nຫົວພັນ', value: 'Houaphan' },
            { label: 'ຊຽງຮ່ອນ', value: 'Xianghoang' },
            { label: 'ວຽງຈັນ', value: 'Vientiane' },
            { label: 'ໄຊສົມບູນ', value: 'Xaysomboun' },
            { label: 'ນະຄອນຫຼວງວຽງຈັນ', value: 'VientianPrefecture' },
            { label: 'ບໍລິຄຳໄຊ', value: 'Bolikhamxai' },
            { label: 'ຄຳມ່ວນ', value: 'Khammouan' },
            { label: 'ສະຫວັນນະເຂດ', value: 'Savannakhet' },
            { label: 'ສາລະວັນ', value: 'Salavan' },
            { label: 'ຈໍາປາສັກ', value: 'Champasak' },
            { label: 'ເຊກອງ', value: 'Sekong' },
            { label: 'nອັດຕະປື', value: 'Attapu' }
        ];
    }
    const rendering = () => {
        const html = [];
        for (let i = 0; i < visitorData.length; i++) {
            const postLink = `/hotel_detail/${visitorData[i]['acc_id']}`;


            html.push(
                <tr key={i} onClick={() => window.location.href = postLink}> 
                    <th>{visitorData[i]['acc_id']}</th>                    
                    <td>{visitorData[i]['acc_name']}</td>
                    <td>{visitorData[i]['acc_address']}</td>
                    <td>{visitorData[i]['acc_tel']}</td>
                    <td>{visitorData[i]['acc_biz_num']}</td>
                    <td>{visitorData[i]['acc_rooms']}</td>
                    <td>{visitorData[i]['acc_rating']}</td>
                    <td style={{ textAlign :'right' }}>{visitorData[i]['acc_devices']}</td>
                </tr>
            )
        }
        return html;
    };

    return (
        <div>  
            <div className="sub_wrp notice detail_box">
                <div className="search_box detail_sub_seach">
                    <form>
                        <input type='search' name='searchKeyword' placeholder={translatedText['Please search your name'] || 'Please search your name'} value={searchKeyword} onChange={handleKeywordChange}/>
                    </form>
                    <button  onClick={handleSearch}>{translatedText['Search'] || 'Search'}</button>
                </div> 
                <div className='detail_select'>
                    <select  ref={selectRef} ame="region" id="region" onChange={handleRegionChange} value={selectedRegion}>
                            <option value="none">{translatedText['SELECT A REGION'] || 'SELET A REGION'}</option>
                        {regionOption.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))} 
                    </select>  
                </div>                
                <h4>{translatedText['hotel'] || 'hotel'}</h4>
                <div>                    
                    <div className='tbwrp'>
                        <table>
                            <colgroup>
                                <col width='5%' />
                                <col width='20%' />
                                <col width='*' />
                                <col width='8%' />
                                <col width='10.9%' />
                                <col width='6%' />
                                <col width='5%' />
                                <col width='6%' />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{translatedText['no'] || 'no'}</th>
                                    <th>{translatedText['name'] || 'name'}</th>
                                    <th>{translatedText['address'] || 'address'}</th>
                                    <th>{translatedText['tel number'] || 'tel number'}</th>
                                    <th>{translatedText['Business Number'] || 'Business Number'}</th>
                                    <th>{translatedText['rooms'] || 'rooms'}</th>
                                    <th>{translatedText['Rating'] || 'Rating'}</th>
                                    <th>{translatedText['devices'] || 'devices'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                { rendering() }  
                            </tbody>
                        </table>
                    </div>
                    <p className='hoteltxt'>{translatedText['Go to detail page when clicked'] || 'Go to detail page when clicked'}</p>
                    <div className='pasing'>
                        <div className='paging'>
                            <PagingComponent
                                pagination = { pagination }
                                params = { params }
                                onPageChange = { movePage }
                            />
                        </div>
                    </div>
                </div>                
            </div>                        
        </div>                          
             
        
    );
}

  
export default Hotel;