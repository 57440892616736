import { useRef, useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { useCookies } from 'react-cookie';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledFileInput = styled.div`
  display: flex;
  gap: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 16px;
  width: 400px;

`;

const AttachmentButton = styled.div`
  width: fit-content;
  padding: 14px;
  background-color: #191b27;
  border-radius: 12px;
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

const Input = styled.input`
  display: none;
`;

const AttachedFile = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #999;
  width: 210px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding : 12px;
`;
const File = ({onFileChange}) => {
    const [translatedText, setTranslatedText] = useState({});
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const language = cookies.language; 
    useEffect(() => {        
        console.log('여기 안탐?')
        console.log(language)

        if (language === '2') {
            console.log('여기 안들어옴?')
            const translatedText = {
                'FILE UPLOAD' : 'ອັບໂຫຼດໄຟລ໌',

            };
            setTranslatedText(translatedText);
            
        } else {
            setTranslatedText({});
        }
    }, [language]);
    const inputEl = useRef(null);
    const [fileName, setFileName] = useState("");
    // const [selectedFile, setSelectedFile] = useState(null); // Declare selectedFile state variable
    const fileInputHandler = useCallback((event) => {
      const files = event.target.files;
      if (files && files[0]) {
        setFileName(event.target.files[0].name);
        const maxSize = 1024 * 1024; // 1MB in bytes
        if (files[0].size > maxSize) {
            console.log("Selected file is too large");
            // You might want to show an error message to the user
        }
        onFileChange(files[0]); // Update selectedFile state with the selected file
      }
    }, [onFileChange]);
  
    useEffect(() => {
        const inputElement = inputEl.current;
      
        inputElement.addEventListener("change", fileInputHandler);
      
        return () => {
          inputElement.removeEventListener("change", fileInputHandler);
        };
    }, [fileInputHandler]);
  
    return (
      <Container>
        <label htmlFor="file">
          <StyledFileInput>
            <AttachmentButton>🔗{translatedText['FILE UPLOAD'] || 'FILE UPLOAD'} </AttachmentButton>
            {fileName ? <AttachedFile key={fileName} className="file-name">{fileName}</AttachedFile> : ""}
          </StyledFileInput>
        </label>
        <Input type="file" id="file" ref={inputEl} />
      </Container>
    );
  };

export default File;