
import React from 'react';

{/*
  Componentize the Paging functionality
  페이지 번호 생성 및 이벤트
  (( created by zk, 230810 ))
*/}


const PagingComponent = ({ pagination, params, onPageChange }) => {
  if (!pagination || !params) {
    return null;
  }

  const pageButtons = [];
  for (let i = pagination.startPage; i <= pagination.endPage; i++) {
    const buttonKey = `pageButton-${i}`;
    if (i !== params.page) {
      pageButtons.push( <li key={buttonKey}><button onClick={() => onPageChange(i)}>{i}</button></li> );
    } else {
      pageButtons.push( <li key={buttonKey}><button className="on">{i}</button></li>);
    }
  }

  return (
    <ul>
      {pagination.existPrevPage && (
        <>
          <li>
            <button onClick={() => onPageChange(1)} className="page_bt first"> 《</button>
          </li>
          <li>
            <button onClick={() => onPageChange(pagination.startPage - 1)} className="page_bt prev">〈</button>
          </li>
        </>
      )}
      { pageButtons }
      { pagination.existNextPage && (
        <>
          <li>
            <button onClick={() => onPageChange(pagination.endPage + 1)} className="page_bt next">〉</button>
          </li>
          <li>
            <button onClick={() => onPageChange(pagination.totalPageCount)} className="page_bt last">	》</button>
          </li>
        </>
      )}
    </ul>
  );
};

export default PagingComponent;
