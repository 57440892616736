import React, { Component, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

// import {setMenuOpen} from './leftmenu'
// import Leftmenu from './leftmenu';
class High extends Component {

  
    constructor(props) {
        super(props);
        this.state = {
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        this.chartRef = React.createRef();
      }
      componentDidMount() {
        window.addEventListener("resize", this.handleWindowResize);
      }
    
      componentWillUnmount() {
        window.removeEventListener("resize", this.handleWindowResize);
      }
    
      handleWindowResize = () => {
        this.setState({
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        });
      };// 풀 화면일 경우 width : 1847정도 사이즈 왼쪽 박스 있을시엔 1573
      
    render() {
        // const { isMenuOpen } = Leftmenu();
        

        const { windowWidth, windowHeight } = this.state;
        const chart_type_value = this.props.chart_type
        const xAxis_type = this.props.xAxis_type
        const title_text = this.props.title_text
        const series2 = this.props.data;
        const select_type = this.props.select_type;
        const width_state = this.props.width_state;
        let width_value
        let dataLabel
        let y_value
        let x_value
        let point_width
        let bottom_font_size
        let win_height
        // console.log('series2',series2)
        if(windowHeight > 850){
          win_height = windowHeight * 0.9 -674
        }
        else if(windowHeight < 800){
          win_height = windowHeight * 0.9 - 250
        }
        else{
          win_height = windowHeight * 0.9 - 550
        }

        if(windowHeight >= 950 && windowHeight < 1050){
          if (select_type === 1){
            y_value = -45
            x_value = -15
          }
          else if (select_type === 2){
            y_value = -35
            x_value = -12
          }
          point_width =50
        }
        else if(windowHeight >= 1050 && windowHeight < 1150){
          // console.log('1050 이상')
          if(select_type === 1){
            y_value = -95
            x_value = -15
          }
          else if(select_type === 2){
            y_value = -85
            x_value = -12
          }
          point_width = 50
        }
        else if(windowHeight >= 1150 ){
          y_value = -140
          x_value = -15
          point_width = 55
        }
        else if(windowHeight < 950 && windowHeight >=850){
          y_value = 0
          x_value = 20
          point_width = 40
        }
        else if(windowHeight < 850){
          y_value = -5
          x_value = 20
          point_width = 40
        }

        //
        if(windowWidth < 768 ){
            y_value = -35
            x_value = 0
            point_width = 20
            bottom_font_size = { style: { fontSize: '10px' } };
        }
        else if(windowWidth > 768 && windowWidth <950){
            y_value = -35
            x_value = 0
            point_width = 25
            bottom_font_size = { style: { fontSize: '12px' } };
        }
        else{
          bottom_font_size = {};
        }
        //

        console.log('windowHeight',windowHeight)
        // console.log(y_value)
        if(chart_type_value === 'spline'){
          dataLabel= {//바 상단의 수치값 개별 지정.
            enabled: false,
            rotation: -360,
            format: '{y}',//수치 표현 포맷
            align: 'top',
            color: '#a562d4', 
            x: x_value,
            y: y_value
            //위치 지정
          }
        }
        else if(chart_type_value === 'column'){
          dataLabel= {//바 상단의 수치값 개별 지정.
            enabled: true,
            rotation: -360,
            format: '{y}',//수치 표현 포맷
            align: 'top',
            color: '#a562d4', 
            x: x_value,
            y:y_value
            //위치 지정
          }
        }
        if(width_state === true){
            width_value = windowWidth -75
        }
        else if(width_state === false){
          if(windowWidth > 768){
            width_value = windowWidth -85- 265
          }
          else{
            width_value = windowWidth -85- 83
          }
        }
        // console.log("width_state",width_state)
        // console.log("windowHeight",windowHeight)
        // console.log("windowWidth",windowWidth)
        // console.log("point_width",point_width)
        var color_list = ['red','green','blue','#636363','rgb(255, 126, 6)', 'rgb(255, 94, 0)',
                          '#646EFF','#69A8AA','#FF82FF','#FFD732','#BE5A5A','#8a0a8a','#CD904A','#D78E8E','#F479DF','#37B7B7','#00008C','#96A5FF'	]
        for(var i=0;i<series2.length;i++){
          console.log(series2[i])
          series2[i]['color'] = color_list[i]
        }
        const options = {
            chart: {
                type: chart_type_value,		// bar차트. 아무 설정이 없으면 line chart가 된다.
                height: win_height, // 창의 높이에 따라 차트 높이를 조정
                width: width_value // 너비 반응형으로 조정
                // width: 1847 // 너비 반응형으로 조정

            },
            tooltip: {
              enabled: false
          },
            title: {
                text: title_text
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: xAxis_type,
                title: {
                  text: null
                },
                labels: bottom_font_size
            },
            yAxis: {
              title: {
                  text: ''
              },
              
          },
          plotOptions: {
            column: {// 컬럼 차트 전체 옵션 지정
              stacking: 'normal',//stacked bar 필수 설정 옵션(default undefined)
              dataLabels: {//컬럼 바 각각의 label 옵션 지정.
                enabled: true,
                style: {
                fontSize:'15px',
                fontWeight:'500',
                textOutline:0,// label 수치의 outline 제거 0으로 지정.

                },
                // 문제 생기면 여기 삭제
                formatter: function () {
                  // 여기서 this.y는 데이터 포인트의 y 값입니다.
                  return Highcharts.numberFormat(this.y, 0, ',', ',');
                },

              }
            },
            series: {
                pointWidth: point_width,
                stacking: 'normal',
                
            }
          },
          colors: ['#058DC7', 'green', 'blue'],

          series: [{
            name : 'Visitors',
            data: series2,
            // ,
            // dataLabels: dataLabel 
          }
          ]

      }
        return (
            <Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={this.chartRef} // 차트 컴포넌트에 ref 추가
          />
      </Fragment>
        );
    }
}
export default High;