import React, { useState,useEffect,useCallback ,useMemo } from 'react';
/////////////////////////////차트 모듈/////////////////////////////
import HighCharts from '.././basic/visitors_chart'
import GenderCharts from '.././basic/gender_chart'
import AgeCharts from '.././basic/age_chart'
////////////////////////////////////////////////////////////
import { useMenuContext } from '../MenuContext';
import { Axios_get, Axios_post,key_setting } from './external_module'; 
import { Cookies, useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
function Dashboard ({handleItemClick}){
    //////////////////////////////////////url_경로////////////////////////////////////////////////////////////
    const url_dashboard = 'web/dashboard';
    const url_age = 'web/dashboard/age';
    const url_sex = 'web/dashboard/gender';
    const url_map = 'web/dashboard/map';
    
    ////////////////////////////////////받아온 데이터 상태 갱신///////////////////////////////////////////////
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const [getData_dashboard, setGetData_dashboard] = useState([]);
    const [regionData, setGetData_map] = useState([]);

    const [visitorsDataDic, setVisitorsDataDic] = useState([]);
    const [ageDataDic, setAgeDataDic] = useState([]);
    const [genderDataDic, setGenderDataDic] = useState([]);
    const [selectedAgeDate, setSelectedAgeDate] = useState(1);
    const [selectedGenderDate, setSelectedGenderDate] = useState(1);
    const [selectedVistorDate, setSelectedVistorDate] = useState(1);
    const [selectedMapDate, setSelectedMapDate] = useState(1);

    const [selectedOption, setSelectedOption] = useState(1);    // select box
    ////////////////////////////////////////////이벤트 /////////////////////////////////////////////
    const { isMenuOpen } = useMenuContext();    // 이건 반응형 차트를 위해 사용됨
    //////////////////////////////////////////// key값 셋팅 //////////////////////////////////////
    //////일단 임시로 key값을 셋팅해서 할건데 나중에 국가가 더 추가되고 추후에도 추가될 예정이라 생각들면 get으로 key값을 받아와서 셋팅
    const { region_key_setting,country_key_setting,continent_key_setting } = key_setting(cookies);  

    const continent_keys = continent_key_setting()
    const country_keys = country_key_setting()
    const region_keys = region_key_setting()
    const age_keys = ["10`s","20`s","30`s","40`s","50`s","60`s","70`s"];
    ///////////////////////////////////////////////////context 셋팅////////////////////////////////
    const context_map = useMemo( () => ({
        date : selectedMapDate
    }),[selectedMapDate]);
    const context_vis = useMemo( () => ({
          chart: 3,
          date: selectedVistorDate,
          type: selectedOption,
    }), [selectedOption,selectedVistorDate]);
    const age_context = useMemo( () => ({
        date : selectedAgeDate
    }), [selectedAgeDate]);
    const gender_context = useMemo( () => ({
        date : selectedGenderDate
    }), [selectedGenderDate]);    
    ////////////////////////////axios셋팅////////////////////////////////////////////////////
    // console.log("COOKIES ", cookies.language);
    // const language = cookies.language || '1'; 
    // const url_dashboard_cookies = url_dashboard + '/' + (language === '2' ? '2' : '1'); 
    const language = cookies.language; 
    console.log('language_dash',language)
    console.log('context_vis',context_vis)
    const url_dashboard_cookies = url_dashboard + '/' + (language === '2' ? '2' : '1'); 
    const url_map_cookies = url_map + '/' + (language === '2' ? '2' : '1'); 

    const fetchData = useCallback(() => {
        console.log("context_vis",context_vis)
        Promise.all([Axios_get(url_dashboard), Axios_post(url_dashboard_cookies,context_vis),Axios_post(url_age,age_context),Axios_post(url_sex,gender_context),Axios_post(url_map_cookies,context_map)])
          .then(([dashboardData, dashboardVisData,dashboardAgeData,dashboardSexData,dashboardMapData]) => {
            if (dashboardData !== null && dashboardVisData !== null ) {
                setGetData_dashboard(dashboardData);
                setVisitorsDataDic(dashboardVisData);
                setAgeDataDic(dashboardAgeData);
                setGenderDataDic(dashboardSexData);
                setGetData_map(dashboardMapData);
                // console.log('dashboardData',dashboardData)
                // console.log('dashboardVisData',dashboardVisData)
                // console.log('dashboardAgeData',dashboardAgeData)
                // console.log("dashboardSexData",dashboardSexData)
            } else {
              console.log('오류가 발생했습니다.');
            }
          });
    }, [context_vis,age_context,gender_context,url_dashboard_cookies,url_map_cookies,context_map]);
      useEffect(() => {
        handleItemClick('lst01')
        fetchData();
        // 10초 주기로 갱신인데 과장님 요구로 일단 주석 
        const interval = setInterval(fetchData, 10000);
        return () => clearInterval(interval);
      }, [fetchData,handleItemClick]);
      console.log('------------------------------------------------------------------------')

      console.log('selectedMapDate',selectedMapDate)
      const getClassByVisitors = visitors => {
        let result
        if(selectedMapDate === 1){
            result = visitors >= 0 && visitors <= 50 ? '01' :
                       visitors >= 51 && visitors <= 100 ? '02' :
                       visitors >= 101 && visitors <= 300 ? '03' :
                      visitors >= 301 && visitors <= 500 ? '04' :
                      visitors >= 501 ? '05' :
                      'defaultClass';
        }
        else if(selectedMapDate === 2){
            result = visitors >= 0 && visitors <= 500 ? '01' :
                       visitors >= 501 && visitors <= 1000 ? '02' :
                       visitors >= 1001 && visitors <= 3000 ? '03' :
                      visitors >= 3001 && visitors <= 5000 ? '04' :
                      visitors >= 5001 ? '05' :
                      '';
        }
        else{
            result = visitors >= 0 && visitors <= 10000 ? '01' :
                    visitors >= 10001 && visitors <= 100000 ? '02' :
                    visitors >= 100001 && visitors <= 300000 ? '03' :
                    visitors >= 300001 && visitors <= 500000 ? '04' :
                    visitors >= 500001 ? '05' :
                    '';
        }
        return result;
    };

    const formatRegionCode = regionCode => {
        return regionCode.toString().padStart(2, '0');
    };
    ////////////////////////////방문객 그래프 셀렉트 박스 value//////////////////////////////////////////
    const handleOptionChange = (e) => {
        setSelectedOption(Number(e.target.value));
      };
    ///////////////////////////////selectedOption 값에 따라 달라질 변수들////////////////////////
    let chart_type_value
    let xAxis_type_value
    let title_text
    let visitorsData
    //////////////////////현재 대륙별, 국가별, 지역별, 월별로 분류/////////////////////////////////////////
    if(selectedOption === 1){
        chart_type_value = 'column'
        xAxis_type_value = 'category' 
        if(language === '2'){
            title_text = 'ທະວີບ'
        }
        else{
            title_text = 'Continent Chart'
        }
        visitorsData = []
        continent_keys.map((continentName,index) => {
            const continentItem = visitorsDataDic?.find(item => item.continent_name === continentName) || {
                cont_count : undefined
            };
            visitorsData.push({name:continentName,y:continentItem.cont_count})
        })
  
    }
    else if(selectedOption === 2){

        chart_type_value = 'column'
        xAxis_type_value = 'category'
        if(language === '2'){
            title_text = 'ປະເທດ'
        }
        else{
            title_text = 'Country Chart'
        }
        visitorsData = []
        country_keys.map((countryName,index) => {
            const countryItem = visitorsDataDic?.find(item => item.country_name === countryName) || {
                country_count : undefined
            };
            visitorsData.push({name:countryName,y:countryItem.country_count})
        })
    }
    else if(selectedOption === 3){
        chart_type_value = 'column'
        xAxis_type_value = 'category'
        if(language === '2'){
            title_text = 'ພາກພື້ນ'
        }
        else{
            title_text = 'Region Chart'
        }
        visitorsData = []
        region_keys.map((regionName,index) => {
            const regionItem = visitorsDataDic?.find(item => item.region_name === regionName) || {
                region_count : undefined
            };
            visitorsData.push({name:regionName,y:regionItem.region_count})
        })
    }

    //////////////////////////////////////////////////////요청할 값들/////////////////////////////////////////////////////////////////
    // 빽단에서 데이터를 가져오기 전에 엑세스를 시도하는 경우 에러 발생 이 상황을 처리하기 위해 정의되었는지 확인 여부 검사 진행 후 데이터 사용 
    // 구체적으로는 어떻게 진행되는지 완전 파악 못함 
    let today = getData_dashboard?.today_visitor?.toLocaleString();
    if(today === undefined){
        today = 0
    }
    console.log("today현재값 알고싶어",today)
    const month = getData_dashboard?.month_visitor?.toLocaleString();
    const year = getData_dashboard?.year_visitor?.toLocaleString();
    let Female_value 
    let Male_value 
    for(let i=0;i<genderDataDic.length;i++){
        if(genderDataDic?.[i]?.['sex'] === 'M'){
            Male_value = genderDataDic?.[i]?.['count_of_people']
        }
        else if(genderDataDic?.[i]?.['sex'] === 'F'){
            Female_value = genderDataDic?.[i]?.['count_of_people']
        }
    }
    let gender_data_set = [{name:'Male',y:Male_value},{name:'Female',y:Female_value}]     

    if(language === '2'){
        gender_data_set = [{name:'ຊາຍ',y:Male_value},{name:'ສະຕຣີ',y:Female_value}]     
    }
             
    const age_list = []
    age_keys.map((ageName, index) => {
        const ageItem = ageDataDic.find(item => item.age_group === ageName) || {
            count_of_people : undefined
        };
        age_list.push({name:ageName,y:ageItem.count_of_people})
    })
    // console.log("age_list",age_list)
    const age_data_set = age_list
    const visitors_data_set = visitorsData

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        if (selectedValue) {
            window.location.href = selectedValue;
          }
    };

    /* 언어변경 */
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
    const [translatedText, setTranslatedText] = useState({});
    console.log("selectedLanguage",selectedLanguage)
    useEffect(() => {
        
        if (language === '2') {
            console.log('여기 왜안타?')
            const translatedText = {
                'Select':'Select1',
                'MAP': 'ແຜນທີ່',
                'Detailed inquiry': 'ສອບຖາມລາຍລະອຽດ',
                'Data management':'ຜູ້ຄຸ້ມຄອງ',
                'Announcement':'ປະກາດ',
                'Click on the desired page':'ໃຫ້ຄລິກໃສ່ຫນ້າທີ່ຕ້ອງການ',
                'check the number of visitors on the map':'ກວດເບິ່ງຈໍານວນນັກທ່ອງທ່ຽວໃນແຜນທີ່',
                'TODAY':'ມື້​ນີ້',
                'MONTH':'ເດືອນນີ້',
                'YEAR':'ປີນີ້',
                'number of visitors':'ຈໍານວນຜູ້ມາຢ້ຽມຢາມ',
                'people':'ຄົນ',
                'AGE CHART':'ຕາຕະລາງອາຍຸ',
                'age of visitor':'ອາຍຸຂອງນັກທ່ອງທ່ຽວ',
                'region':'ພາກພື້ນ',
                'continent':'ທະວີບ',
                'country':'ປະເທດ',
                'GENDER CHART':'ຕາຕະລາງເພດ',
                'visitor gender':'ເພດຂອງນັກທ່ອງທ່ຽວ',
                'Day':'ມື້',
                'Month':'ເດືອນນີ້',
                'Year':'ປີນີ້',
                'Visitors status':'ນັກທ່ອງທ່ຽວ ສະຖານະພາບ',
                'visitor detail graph':'ເສັ້ນສະແດງລາຍລະອຽດຂອງຜູ້ເຂົ້າຊົມ'
            };
            setTranslatedText(translatedText);
        } else {
            setTranslatedText({});
        }
    }, [language]);

    return(
       <div>
            <div className="search_box">
                <select onChange={handleSelectChange} className={translatedText['Select'] || 'Select'}>
                    <option value="" className='basic_txt'>{translatedText['Click on the desired page'] || 'Click on the desired page'}</option>
                    <option value="detail">{translatedText['Detailed inquiry'] || 'Detailed inquiry'}</option>
                    <option value="data_management">{translatedText['Data management'] || 'Data management'}</option>
                    <option value="noticelist">{translatedText['Announcement'] || 'Announcement'}</option>
                </select>
            </div>

            <div className="graph_wrp">
                <div className="top_graph">
                    <div className="lft">
                        <div className={`mapbox box ${selectedMapDate === 1 ? 'map_day_image' : ''} ${selectedMapDate === 2 ? 'map_month_image' : ''} ${selectedMapDate === 3 ? 'map_year_image' : ''}`}>
                            <div className="box_right">
                                <input type="checkbox" name="age_date" id='cDay' value={1} checked={selectedMapDate === 1} onChange={() => setSelectedMapDate(1)}/>
                                <label For="cDay">{translatedText['Day'] || 'Day'}</label>
                                <input type="checkbox" name="age_date" id='cMonth' value={2} checked={selectedMapDate === 2} onChange={() => setSelectedMapDate(2)}/>
                                <label For="cMonth">{translatedText['Month'] || 'Month'}</label>
                                <input type="checkbox" name="age_date" id='cYear' value={3} checked={selectedMapDate === 3} onChange={() => setSelectedMapDate(3)}/>
                                <label For="cYear">{translatedText['Year'] || 'Year'}</label>
                            </div>
                            <h4>{translatedText['MAP'] || 'MAP'}</h4>
                            
                            <h5>{translatedText['check the number of visitors on the map'] || 'check the number of visitors on the map'}</h5>
                            
                            <div className="map_box">
                                <ul>
                                {region_keys.map((regionName, index) => {
                                        const regionItem = regionData.find(item => item.region_name === regionName) || {
                                            region_code: index + 1,
                                            region_count: 0,
                                        };
                                        return (
                                            <li
                                                key={index}
                                                className={`map${formatRegionCode(regionItem.region_code)} map${formatRegionCode(regionItem.region_code)}${getClassByVisitors(regionItem.region_count,selectedMapDate)}`}
                                            >
                                                <p>{regionName}</p>
                                            </li>
                                        );
                                    })}

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="rht">
                        <div className="boxwp01">
                            <div className="box box01">
                                <h4>{translatedText['TODAY'] || 'TODAY'}</h4>
                                <h5>{translatedText['number of visitors'] || 'number of visitors'} </h5>
                                <p>{today}<span className="col">{translatedText['people'] || 'people'}</span></p>
                            </div>
                            <div className="box box02">
                                <h4>{translatedText['MONTH'] || 'MONTH'}</h4>
                                <h5>{translatedText['number of visitors'] || 'number of visitors'} </h5>
                                <p>{month}<span className="col">{translatedText['people'] || 'people'}</span></p>
                            </div>
                            <div className="box box03">
                                <h4>{translatedText['YEAR'] || 'YEAR'}</h4>
                                <h5>{translatedText['number of visitors'] || 'number of visitors'} </h5>
                                <p>{year}<span className="col">{translatedText['people'] || 'people'}</span></p>
                            </div>
                        </div>
                        <div className="boxwp02">
                            <div className="box">
                                <div className="box_right">
                                    <input type="checkbox" name="age_date" id='aDay'  value={1} checked={selectedAgeDate === 1} onChange={() => setSelectedAgeDate(1)}/>
                                    <label For="aDay">{translatedText['Day'] || 'Day'}</label>
                                    <input type="checkbox" name="age_date" id='aMonth' value={2} checked={selectedAgeDate === 2} onChange={() => setSelectedAgeDate(2)}/>
                                    <label For="aMonth">{translatedText['Month'] || 'Month'}</label>
                                    <input type="checkbox" name="age_date" id='aYear' value={3} checked={selectedAgeDate === 3} onChange={() => setSelectedAgeDate(3)}/>
                                    <label For="aYear">{translatedText['Year'] || 'Year'}</label>
                                </div>
                                <h4>{translatedText['AGE CHART'] || 'AGE CHART'}</h4>
                                <h5>{translatedText['age of visitor'] || 'age of visitor'}</h5>
                                <div className="graph_box">
                                    <AgeCharts  data={age_data_set} />

                                </div>
                            </div>
                            <div className="box">
                                <div className="box_right">
                                    <input type="checkbox" name="age_date" id='gDay' value={1} checked={selectedGenderDate === 1} onChange={() => setSelectedGenderDate(1)}/>
                                    <label For="gDay">{translatedText['Day'] || 'Day'}</label>
                                    <input type="checkbox" name="age_date" id='gMonth' value={2} checked={selectedGenderDate === 2} onChange={() => setSelectedGenderDate(2)}/>
                                    <label For="gMonth">{translatedText['Month'] || 'Month'}</label>
                                    <input type="checkbox" name="age_date" id='gYear' value={3} checked={selectedGenderDate === 3} onChange={() => setSelectedGenderDate(3)}/>
                                    <label For="gYear">{translatedText['Year'] || 'Year'}</label>
                                </div>
                                <h4>{translatedText['GENDER CHART'] || 'GENDER CHART'}</h4>
                                <h5>{translatedText['visitor gender'] || 'visitor gender'}</h5>
                                <div className="graph_box">
                                    <GenderCharts  data={gender_data_set} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bot_graph box">
                    <div className="box_right">
                        <input type="checkbox" name="age_date" id='bDay' value={1} checked={selectedVistorDate === 1} onChange={() => setSelectedVistorDate(1)}/>
                        <label For="bDay">{translatedText['Day'] || 'Day'}</label>
                        <input type="checkbox" name="age_date" id='bMonth' value={2} checked={selectedVistorDate === 2} onChange={() => setSelectedVistorDate(2)}/>
                        <label For="bMonth">{translatedText['Month'] || 'Month'}</label>
                        <input type="checkbox" name="age_date" id='bYear' value={3} checked={selectedVistorDate === 3} onChange={() => setSelectedVistorDate(3)}/>
                        <label For="bYear">{translatedText['Year'] || 'Year'}</label>
                    </div>
                    <h4>{translatedText['Visitors status'] || 'Visitors status'}</h4>
                    <h5>{translatedText['visitor detail graph'] || 'visitor detail graph'}</h5>
                    <p className="select_wrp">
                        <select name="" id="" onChange={handleOptionChange} value={selectedOption}>
                            <option value={1}>{translatedText['continent'] || 'continent'}</option>   {/* 대륙별 */}
                            <option value={2}>{translatedText['country'] || 'country'}</option>   {/* 국가별 */}
                            <option value={3}>{translatedText['region'] || 'region'}</option>   {/* 지역별 */}
                        </select>
                    </p>
                    <div className="graph_box">
                    <HighCharts data={visitors_data_set} width_state={isMenuOpen} 
                        title_text={title_text} chart_type={chart_type_value} xAxis_type={xAxis_type_value} select_type={selectedOption}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard