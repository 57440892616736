import React, { useEffect, useState, useCallback } from 'react';
import { Axios_post } from './external_module';
import { useLocation } from 'react-router-dom';
import PagingComponent from '../basic/PagingComponent';
import { useCookies } from 'react-cookie';

function Region ()  {
    let url = 'web/detail/region'

    /* 언어변경 */
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
    const [translatedText, setTranslatedText] = useState({});
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const language = cookies.language; 


    useEffect(() => {
        // if (cookies.language === 2) {
        if (language === '2') {
            const translatedText = {
                'ALL' : 'ໃຜໆກໍ',
                'Please enter your search term' : 'ກະລຸນາໃສ່ຄໍາຄົ້ນຫາຂອງທ່ານ',

                'Details by country': 'ລາຍລະອຽດຕາມປະເທດ',
                'AGE':'ອາຍຸ',
                'GENDER':'ເພດ',
                'december':'ທັນວາ',
                'November':'ພະຈິກ',
                'october':'ຕຸລາ',
                'September':'ກັນຍາ',
                'August':'ສິງຫາ',
                'Search': 'ຊອກຫາ',

                'July':'ກໍລະກົດ',
                'June':'ມິຖຸນາ',
                'May':'ພຶດສະພາ',
                'april':'ເມສາ',
                'March':'ມີນາ',
                'february':'ກຸມພາ',
                'january':'ມັງກອນ',
                'division':'ພະແນກ'
            };
        setTranslatedText(translatedText);

        } else {
        setTranslatedText({});
        }
    }, [language]);  

    // 데이터 로드 및 페이징 관련 변수
    const [selectedAge, setSelectedAge] = useState(['10', '20', '30', '40', '50', '60', '70', '80', '90']);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [noticeData, setNoticeData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [selectedGender, setSelectedGender] = useState('all');
    const handleSearch = () => {
        // 검색 버튼 클릭 시 검색어 값을 상태에 저장
        setSearchKeyword(searchKeyword); // searchKeyword 값을 저장
        // 이후 원하는 작업 수행 (예: 서버 요청 등)
        console.log('Search keyword:', searchKeyword);
    };
    const context = {
        sex: selectedGender,
        ageGroup: selectedAge,
        region_name : searchKeyword
    };
    console.log("context",context)
    let genderOptions = [
        { label: 'All', value: 'all' },
        { label: 'Man', value: 'Man' },
        { label: 'Woman', value: 'Female' }
    ];
   
    if(language === '2'){
        genderOptions = [
            { label: 'ໃຜໆກໍ', value: 'all' },
            { label: 'ຊາຍ', value: 'Man' },
            { label: 'ສະຕຣີ', value: 'Female' }
        ];
    }
    
    const [params, setParams] = useState({ page: 1, recordSize: 10, pageSize: 5 });
    const [checkboxState, setCheckboxState] = useState({
        ageall: true,
        age10: false,
        age20: false,
        age30: false,
        age40: false,
        age50: false,
        age60: false,
        age70: false,
        age80: false,
        age90: false,
    });
    useEffect(() => {

        const getUrl = url + '/' + cookies.language;
        Axios_post(getUrl, context, params).then((result) => {
            if (result !== null) {
                console.log("result",result)
                const modifiedData = transformData(result);
                console.log("modifiedData",modifiedData)
                setNoticeData(modifiedData);
                setPagination(result.pagination);
            } else {
                console.log('오류가 발생했습니다.');
            }
        });
    }, [language, context.sex, context.ageGroup,context.region_name, params.page]);
    const transformData = (originalData) => {
        const transformedData = [];
        const months = ['january', 'february', 'March', 'april', 'May', 'June', 'July', 'August', 'September', 'october', 'November', 'december'];
    
        originalData.forEach((item) => {
            const existingItem = transformedData.find((tItem) => tItem.region_name === item.region_name);
    
            if (existingItem) {
                months.forEach((month) => {
                    existingItem[month] += item.month === months.indexOf(month) + 1 ? item.region_count : 0;
                });
            } else {
                const transformedItem = {
                    region_name: item.region_name,
                    ...months.reduce((acc, month) => {
                        acc[month] = item.month === months.indexOf(month) + 1 ? item.region_count : 0;
                        return acc;
                    }, {})
                };
                transformedData.push(transformedItem);
            }
        });
    
        return transformedData;
    };
    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxState((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
        handleAgeChange(name, checked);
    };
    {/*
        페이지 번호 클릭 및 해당 페이지로 이동
     */}
     const movePage = useCallback( async ( page ) => {
        setParams({ ...params, page });
        console.log("PageNumber >> ", page);
    }, [params, pagination]);    
    const handleAgeChange = (value, isChecked) => {
        let updatedCheckboxState = { ...checkboxState };

        if (value === 'ageall') {
            updatedCheckboxState = {
                ageall: true,
                age10: false,
                age20: false,
                age30: false,
                age40: false,
                age50: false,
                age60: false,
                age70: false,
                age80: false,
                age90: false,
            };
        } else {
            updatedCheckboxState[value] = isChecked;
            updatedCheckboxState.ageall = false;
        }

        const updatedListAge = [];

        if (updatedCheckboxState.ageall) {
            updatedListAge.push('10', '20', '30', '40', '50', '60', '70', '80', '90');
        } else {
            if (updatedCheckboxState.age10) updatedListAge.push('10');
            if (updatedCheckboxState.age20) updatedListAge.push('20');
            if (updatedCheckboxState.age30) updatedListAge.push('30');
            if (updatedCheckboxState.age40) updatedListAge.push('40');
            if (updatedCheckboxState.age50) updatedListAge.push('50');
            if (updatedCheckboxState.age60) updatedListAge.push('60');
            if (updatedCheckboxState.age70) updatedListAge.push('70');
            if (updatedCheckboxState.age80) updatedListAge.push('80');
            if (updatedCheckboxState.age90) updatedListAge.push('90');
        }

        setCheckboxState(updatedCheckboxState);
        setSelectedAge(updatedListAge);
    };

    const rendering = () => {
        const html = [];
        for (let i = 0; i < noticeData.length; i++) {
            html.push(
                <tr key={i}>
                    <th>{noticeData[i]['region_name']}</th>
                    {Object.keys(noticeData[i]).map((key) => {
                        if (key !== 'region_name') {
                            const formattedValue = typeof noticeData[i][key] === 'number'
                                ? noticeData[i][key].toLocaleString()
                                : noticeData[i][key];
                            return <td key={key}>{formattedValue}</td>;
                        }
                        return null;
                    })}
                </tr>
            );
        }
        return html;
    };

    return (
        <div className='detail_box'>  
            <div className="search_box">
                <form>
                    <input type='search' name='searchKeyword' placeholder={translatedText['Please enter your search term'] || 'Please enter your search term'} value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)}/>
                </form>
                <button onClick={handleSearch}>{translatedText['Search'] || 'Search'}</button>
            </div>  
            <div className='radio_wrp'>
                <form>
                    <div className='bline'>
                        <p className='tit'>{translatedText['GENDER'] || 'GENDER'}</p>
                         {genderOptions.map(option => (
                            <div key={option.value}>
                                <input type="radio" name="gender" id={option.value} value={option.value} onChange={handleGenderChange} checked={selectedGender === option.value} />
                            <label htmlFor={option.value}>{option.label}</label>
                            </div>
                         ))}              
                    </div>
                    <div>
                        <p className='tit'>{translatedText['AGE'] || 'AGE'}</p>
                        <input type="checkbox" name="ageall" id="ageall" value="ageall" checked={checkboxState.ageall} onChange={handleCheckboxChange} />
                        <label For="ageall">{translatedText['ALL'] || 'ALL'}</label>
                        <input type="checkbox" name="age10" id="age10" value="age10" checked={checkboxState.age10} onChange={handleCheckboxChange} />
                        <label For="age10">10's</label>
                        <input type="checkbox" name="age20" id="age20" value="age20" checked={checkboxState.age20} onChange={handleCheckboxChange} />
                        <label For="age20">20's</label>
                        <input type="checkbox" name="age30" id="age30" value="age30" checked={checkboxState.age30} onChange={handleCheckboxChange} />
                        <label For="age30">30's</label>
                        <input type="checkbox" name="age40" id="age40" value="age40" checked={checkboxState.age40} onChange={handleCheckboxChange} />
                        <label For="age40">40's</label>
                        <input type="checkbox" name="age50" id="age50" value="age50" checked={checkboxState.age50} onChange={handleCheckboxChange} />
                        <label For="age50">50's</label>
                        <input type="checkbox" name="age60" id="age60" value="age60" checked={checkboxState.age60} onChange={handleCheckboxChange} />
                        <label For="age60">60's</label>
                        <input type="checkbox" name="age70" id="age70" value="age70" checked={checkboxState.age70} onChange={handleCheckboxChange} />
                        <label For="age70">70's</label>
                        <input type="checkbox" name="age80" id="age80" value="age80" checked={checkboxState.age80} onChange={handleCheckboxChange} />
                        <label For="age80">80's</label>
                        <input type="checkbox" name="age90" id="age90" value="age90" checked={checkboxState.age90} onChange={handleCheckboxChange} />
                        <label For="age90">90's</label>
                    </div>                    
                </form>
            </div>
            <div className="notice">
                <h4>{translatedText['Details by country'] || 'Details by country'}</h4>
                <div>
                    <div className='tbwrp tbwrp02'>
                        <table className='detail_num'>
                            <colgroup>
                                <col width='8%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                            </colgroup>
                            <thead>
                            <tr>
                                    <th>{translatedText['division'] || 'division'}</th>
                                    <th>{translatedText['january'] || 'january'}</th>
                                    <th>{translatedText['february'] || 'february'}</th>
                                    <th>{translatedText['March'] || 'March'}</th>
                                    <th>{translatedText['april'] || 'april'}</th>
                                    <th>{translatedText['May'] || 'May'}</th>
                                    <th>{translatedText['June'] || 'June'}</th>
                                    <th>{translatedText['July'] || 'July'}</th>
                                    <th>{translatedText['August'] || 'August'}</th>
                                    <th>{translatedText['September'] || 'September'}</th>
                                    <th>{translatedText['october'] || 'october'}</th>
                                    <th>{translatedText['November'] || 'November'}</th>
                                    <th>{translatedText['december'] || 'december'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rendering()}  
                            </tbody>
                        </table>
                    </div>
                    <div className='pasing paging'>
                        <PagingComponent
                            pagination = { pagination }
                            params = { params }
                            onPageChange = { movePage }
                        />
                    </div>
                </div>
            </div>                         
        </div>                          
             
        
    );
}

  
export default Region;