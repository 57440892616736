import React, { createContext, useState } from "react";


export const ConfirmContext = createContext();

const ConfirmContextProvider = ({ children }) => {

  const [confirmList, setConfirmList] = useState([]);


  return (

    <ConfirmContext.Provider value={[confirmList, setConfirmList]}>
      {children}
    </ConfirmContext.Provider>

  );

};

export default ConfirmContextProvider;