import React, { useState } from 'react';
import { useUrl, Axios_post } from './external_module';
import { useCookies } from 'react-cookie';

function Login() {
  const { dashboard_button } = useUrl();
  const [userId, setUserId] = useState('');
  const [userPw, setUserPw] = useState('');
  const [ , setCookie ] = useCookies(['sessionID','userGrade','language']);

  localStorage.clear();
  ////////////////////////////////////////엔터 이벤트//////////////////////////////
  const Enter_event = (event) => {
    if (event.key === 'Enter') {
      login_check();
    }
  };
  ////////////////////////////////////////////////////////////////////////////////

  const login_check = () => {
    const url = '/web/login';
    const context = {
      username: userId,
      password: userPw,
    };


    Axios_post(url, context, { withCredentials: true }).then((result) => {
      if (result !== null) {
        console.log("result", result);
        if (result['result'] === true) {
          dashboard_button();

          /*
            Cookies
              - SessionID
              - Grade : 사용자 등급 ( 1: user / 2: staff / 3: admin )
              - Name : language ( 1: English / 2: Laos )
          
          */
          setCookie("sessionID", result["id"], {path: "/"});
          setCookie('language', 1, {path: "/"});
          const userGrade = 0;
          if( result['is_superuser'] === 1 && result['is_staff'] === 1  ) {
            setCookie('userGrade', 3, {path: "/"});
          } else if( result['is_superuser'] === 0 && result['is_staff'] === 1 ) {
            setCookie('userGrade', 2, {path: "/"});
          } else if( result['is_superuser'] === 0 && result['is_staff'] === 0 ) {
            setCookie('userGrade', 1, {path: "/"});
          }
          


        } else if (result['result'] === false) {
          const message = `아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력했습니다. 
          입력하신 내용을 다시 확인해주세요.`;
          alert(message);
        }
      }
      else {
        console.log('오류가 발생했습니다.');
      }
    });
  };

  return (
    <div id="wrap">
      <div className="contents clear_fix">
        <div className="login_page">
          <div className="login_box">
            <h2>ADMIN PAGE LOGIN</h2>

            <div className="inpbox">
              <div className="icell01 icell">
                <span>
                  <label htmlFor="userid">ID</label>
                </span>
                <input
                  type="text"
                  title="ID"
                  placeholder="Please enter your id"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  onKeyDown={Enter_event}

                  autoComplete="on"
                />
              </div>
              <div className="icell02 icell">
                <span>
                  <label htmlFor="userpw">PASSWORD</label>
                </span>
                <input
                  type="password"
                  title="PASSWORD"
                  placeholder="Please enter your password"
                  value={userPw}
                  onChange={(e) => setUserPw(e.target.value)}
                  onKeyDown={Enter_event}

                  autoComplete="off"
                />
              </div>

              <div className="btnwrp">
                <button type="submit" title="LOGIN" onClick={login_check}>LOGIN</button>
              </div>
              <p className="joincheck">
                <input type="checkbox" name="agree" id="agree" className="button" title="auto login" value="Y" />
                <label htmlFor="agree">auto login</label>
              </p>
            </div>
            <p className="footlogo">
              <img src="../images/main_logo.png" alt="SMSOFT" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;