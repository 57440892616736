import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Hotel_management from "./hotel_management";
import User_management from "./user_management";
import Visitor_management from "./visitor_management";
import { Cookies, useCookies } from 'react-cookie';


const menuList = {
    0:<Hotel_management/>,
    1:<User_management/>,
    2:<Visitor_management/>
}
function Datamanage({handleItemClick}) {
  useEffect(() => {
    handleItemClick('lst03')
    
  }, [handleItemClick]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
  const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);
  const [translatedText, setTranslatedText] = useState({});
  const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);
  const language = cookies.language; 

  useEffect(() => {
    if (language === '2') {
      const translatedText = {
        'HOTEL': 'ໂຮງແຮມ',
        'USER':'ຜູ້ໃຊ້',
        'VISITOR':'ນັກທ່ອງທ່ຽວ'
      };
      setTranslatedText(translatedText);
    } else {
      setTranslatedText({});
    }
  }, [language]);

  const [menu, setMenu] = useState(0);

  const changeMenu = (menuIndex) => {
    setMenu(menuIndex);
  };

  return (
    <div className="sub_wrp">
      <ul className="tabs">
        <li className={`${menu === 0? 'active': ''}`} onClick={() => changeMenu(0)}>{translatedText['HOTEL'] || 'HOTEL'}</li>
        <li className={`${menu === 1? 'active': ''}`} onClick={() => changeMenu(1)}>{translatedText['USER'] || 'USER'}</li>
        <li className={`${menu === 2? 'active': ''}`} onClick={() => changeMenu(2)}>{translatedText['VISITOR'] || 'VISITOR'}</li>
      </ul>
      <div className="contentArea">
        {menuList[menu]}
      </div>
    </div>
  );
}

export default Datamanage;

