
import React, { useEffect, useState, useCallback } from 'react';
import { Axios_post } from './external_module';
import PagingComponent from '../basic/PagingComponent';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function Nation() {
    let url = 'web/detail/country'

    /* 언어변경 */
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
    const [translatedText, setTranslatedText] = useState({});
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const language = cookies.language; 
    useEffect(() => {        
        console.log('여기 안탐?')
        console.log(language)

        if (language === '2') {
            console.log('여기 안들어옴?')
            const translatedText = {
                'Please enter your search term' : 'ກະລຸນາໃສ່ຄໍາຄົ້ນຫາຂອງທ່ານ',

                'ALL' : 'ໃຜໆກໍ',
                'Details by country': 'ລາຍລະອຽດຕາມປະເທດ',
                'AGE':'ອາຍຸ',
                'GENDER':'ເພດ',
                'december':'ທັນວາ',
                'Search': 'ຊອກຫາ',
                'SELECT A REGION' : 'ເລືອກພາກພື້ນ',
                'November':'ພະຈິກ',
                'october':'ຕຸລາ',
                'September':'ກັນຍາ',
                'August':'ສິງຫາ',
                'July':'ກໍລະກົດ',
                'June':'ມິຖຸນາ',
                'May':'ພຶດສະພາ',
                'april':'ເມສາ',
                'March':'ມີນາ',
                'february':'ກຸມພາ',
                'january':'ມັງກອນ',
                'division':'ພະແນກ'
            };
            setTranslatedText(translatedText);
            
        } else {
            setTranslatedText({});
        }
    }, [language]);
// }, [selectedLanguage, cookies.language]);

    const [searchKeyword, setSearchKeyword] = useState('');

    const [selectedGender, setSelectedGender] = useState('all');
    const [selectedAge, setSelectedAge] = useState(['10', '20', '30', '40', '50', '60', '70', '80', '90']);
    const [selectedRegion, setSelectedRegion] = useState();
    const [checkboxState, setCheckboxState] = useState({
        ageall: true,
        age10: false,
        age20: false,
        age30: false,
        age40: false,
        age50: false,
        age60: false,
        age70: false,
        age80: false,
        age90: false,
    });
    //console.log('searchKeyword',searchKeyword)
    const handleSearch = () => {
        // 검색 버튼 클릭 시 검색어 값을 상태에 저장
        setSearchKeyword(searchKeyword); // searchKeyword 값을 저장
        // 이후 원하는 작업 수행 (예: 서버 요청 등)
        console.log('Search keyword:', searchKeyword);
    };
    const context = {
        sex: selectedGender,
        ageGroup: selectedAge,
        region_name: selectedRegion,
        country_name : searchKeyword
    };
    //console.log("context",context)

    const [noticeData, setNoticeData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [params, setParams] = useState({ page: 1, recordSize: 10, pageSize: 5 });

    // Cookie의 Language 값에 따라 요청 url 변경
    const getUrl = url + '/' + language;

    useEffect(() => {
        Axios_post(getUrl, context, params).then((result) => {
            if (result !== null) {
                console.log("result",result)
                const modifiedData = transformData(result);
                setNoticeData(modifiedData);
                setPagination(result.pagination);
            } else {
                console.log('오류가 발생했습니다.');
            }
        });
    }, [language, context.sex, context.ageGroup, context.region_name,context.country_name, params.page]);
// }, [selectedLanguage,cookies.language, context.sex, context.ageGroup, context.region_name,context.country_name, params.page]);

    const movePage = useCallback(async (page) => {
        setParams({ ...params, page });
    }, [params]);

    const transformData = (originalData) => {
        const transformedData = [];
        const months = ['january', 'february', 'March', 'april', 'May', 'June', 'July', 'August', 'September', 'october', 'November', 'december'];
    
        originalData.forEach((item) => {
            const existingItem = transformedData.find((tItem) => tItem.country_name === item.country_name);
    
            if (existingItem) {
                months.forEach((month) => {
                    existingItem[month] += item.month === months.indexOf(month) + 1 ? item.visitor_count : 0;
                });
            } else {
                const transformedItem = {
                    country_name: item.country_name,
                    ...months.reduce((acc, month) => {
                        acc[month] = item.month === months.indexOf(month) + 1 ? item.visitor_count : 0;
                        return acc;
                    }, {})
                };
                transformedData.push(transformedItem);
            }
        });
    
        return transformedData;
    };

    let genderOptions = [
        { label: 'All', value: 'all' },
        { label: 'Man', value: 'Man' },
        { label: 'Woman', value: 'Female' }
    ];
   
    if(language === '2'){
        genderOptions = [
            { label: 'ໃຜໆກໍ', value: 'all' },
            { label: 'ຊາຍ', value: 'Man' },
            { label: 'ສະຕຣີ', value: 'Female' }
        ];
    }
    // region_keys = [
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    //     
    let regionOption
    console.log("language여기도 잘 나오니?",language)
    if(language === '1'){
        regionOption = [
            { label: 'Phongsali', value: 'Phongsali' },
            { label: 'LouangNamtha', value: 'LouangNamtha' },
            { label: 'Bokeo', value: 'Bokeo' },
            { label: 'Oudomxai', value: 'Oudomxai' },
            { label: 'Sainyabuli', value: 'Sainyabuli' },
            { label: 'LouangPrabang', value: 'LouangPrabang' },
            { label: 'Houaphan', value: 'Houaphan' },
            { label: 'Xianghoang', value: 'Xianghoang' },
            { label: 'Vientiane', value: 'Vientiane' },
            { label: 'Xaysomboun', value: 'Xaysomboun' },
            { label: 'VientianPrefecture', value: 'VientianPrefecture' },
            { label: 'Bolikhamxai', value: 'Bolikhamxai' },
            { label: 'Khammouan', value: 'Khammouan' },
            { label: 'Savannakhet', value: 'Savannakhet' },
            { label: 'Salavan', value: 'Salavan' },
            { label: 'Champasak', value: 'Champasak' },
            { label: 'Sekong', value: 'Sekong' },
            { label: 'Attapu', value: 'Attapu' }
        ];
    }
    else if(language === '2'){
        regionOption = [
            { label: 'ຜົ້ງສາລີ', value: 'Phongsali' },
            { label: 'ຫຼວງນ້ຳທາ', value: 'LouangNamtha' },
            { label: 'ບໍ່ແກ້ວ', value: 'Bokeo' },
            { label: 'ອຸດົມໄຊ', value: 'Oudomxai' },
            { label: 'ໄຊຍາບູລີ', value: 'Sainyabuli' },
            { label: 'ຫຼວງ​ພະ​ບາງ', value: 'LouangPrabang' },
            { label: 'nຫົວພັນ', value: 'Houaphan' },
            { label: 'ຊຽງຮ່ອນ', value: 'Xianghoang' },
            { label: 'ວຽງຈັນ', value: 'Vientiane' },
            { label: 'ໄຊສົມບູນ', value: 'Xaysomboun' },
            { label: 'ນະຄອນຫຼວງວຽງຈັນ', value: 'VientianPrefecture' },
            { label: 'ບໍລິຄຳໄຊ', value: 'Bolikhamxai' },
            { label: 'ຄຳມ່ວນ', value: 'Khammouan' },
            { label: 'ສະຫວັນນະເຂດ', value: 'Savannakhet' },
            { label: 'ສາລະວັນ', value: 'Salavan' },
            { label: 'ຈໍາປາສັກ', value: 'Champasak' },
            { label: 'ເຊກອງ', value: 'Sekong' },
            { label: 'nອັດຕະປື', value: 'Attapu' }
        ];
    }
    

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckboxState((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
        handleAgeChange(name, checked);
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handleRegionChange = (event) => {
        const selectedValue = event.target.value;
        if (selectedValue == "none") {
            setSelectedRegion(undefined);
        } else {
            setSelectedRegion(selectedValue);
        }
    };

    const handleAgeChange = (value, isChecked) => {
        let updatedCheckboxState = { ...checkboxState };

        if (value === 'ageall') {
            updatedCheckboxState = {
                ageall: true,
                age10: false,
                age20: false,
                age30: false,
                age40: false,
                age50: false,
                age60: false,
                age70: false,
                age80: false,
                age90: false,
            };
        } else {
            updatedCheckboxState[value] = isChecked;
            updatedCheckboxState.ageall = false;
        }

        const updatedListAge = [];

        if (updatedCheckboxState.ageall) {
            updatedListAge.push('10', '20', '30', '40', '50', '60', '70', '80', '90');
        } else {
            if (updatedCheckboxState.age10) updatedListAge.push('10');
            if (updatedCheckboxState.age20) updatedListAge.push('20');
            if (updatedCheckboxState.age30) updatedListAge.push('30');
            if (updatedCheckboxState.age40) updatedListAge.push('40');
            if (updatedCheckboxState.age50) updatedListAge.push('50');
            if (updatedCheckboxState.age60) updatedListAge.push('60');
            if (updatedCheckboxState.age70) updatedListAge.push('70');
            if (updatedCheckboxState.age80) updatedListAge.push('80');
            if (updatedCheckboxState.age90) updatedListAge.push('90');
        }

        setCheckboxState(updatedCheckboxState);
        setSelectedAge(updatedListAge);
    };

    

    const rendering = () => {
        const html = [];
        for (let i = 0; i < noticeData.length; i++) {
            html.push(
                <tr key={i}>
                    <th>{noticeData[i]['country_name']}</th>
                    {Object.keys(noticeData[i]).map((key) => {
                        if (key !== 'country_name') {
                            const formattedValue = typeof noticeData[i][key] === 'number'
                                ? noticeData[i][key].toLocaleString()
                                : noticeData[i][key];
                            return <td key={key}>{formattedValue}</td>;
                        }
                        return null;
                    })}
                </tr>
            );
        }
        return html;
    };
    return (
        <div className='detail_box nation'>  
            <div className="search_box">
                <form>
                    <input type='search' name='searchKeyword' placeholder={translatedText['Please enter your search term'] || 'Please enter your search term'} value={searchKeyword} onChange={(e) => setSearchKeyword(e.target.value)}/>
                </form>
                <button onClick={handleSearch}>{translatedText['Search'] || 'Search'}</button>
            </div>
            <div className='region_select'>                    
                    <select ame="region" id="region" onChange={handleRegionChange} value={selectedRegion}>
                            <option value="none">{translatedText['SELECT A REGION'] || 'SELET A REGION'}</option>
                        {regionOption.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))} 
                    </select>                                       
                </div>  
            <div className='radio_wrp'>
                <form>
                    <div className='bline'>
                        <p className='tit'>{translatedText['GENDER'] || 'GENDER'}</p>
                         {genderOptions.map(option => (
                            <div key={option.value}>
                                <input type="radio" name="gender" id={option.value} value={option.value} onChange={handleGenderChange} checked={selectedGender === option.value} />
                            <label htmlFor={option.value}>{option.label}</label>
                            </div>
                         ))}              
                    </div>
                    <div>
                        <p className='tit'>{translatedText['AGE'] || 'AGE'}</p>
                        <input type="checkbox" name="ageall" id="ageall" value="ageall" checked={checkboxState.ageall} onChange={handleCheckboxChange} />
                        <label For="ageall">{translatedText['ALL'] || 'ALL'}</label>
                        <input type="checkbox" name="age10" id="age10" value="age10" checked={checkboxState.age10} onChange={handleCheckboxChange} />
                        <label For="age10">10's</label>
                        <input type="checkbox" name="age20" id="age20" value="age20" checked={checkboxState.age20} onChange={handleCheckboxChange} />
                        <label For="age20">20's</label>
                        <input type="checkbox" name="age30" id="age30" value="age30" checked={checkboxState.age30} onChange={handleCheckboxChange} />
                        <label For="age30">30's</label>
                        <input type="checkbox" name="age40" id="age40" value="age40" checked={checkboxState.age40} onChange={handleCheckboxChange} />
                        <label For="age40">40's</label>
                        <input type="checkbox" name="age50" id="age50" value="age50" checked={checkboxState.age50} onChange={handleCheckboxChange} />
                        <label For="age50">50's</label>
                        <input type="checkbox" name="age60" id="age60" value="age60" checked={checkboxState.age60} onChange={handleCheckboxChange} />
                        <label For="age60">60's</label>
                        <input type="checkbox" name="age70" id="age70" value="age70" checked={checkboxState.age70} onChange={handleCheckboxChange} />
                        <label For="age70">70's</label>
                        <input type="checkbox" name="age80" id="age80" value="age80" checked={checkboxState.age80} onChange={handleCheckboxChange} />
                        <label For="age80">80's</label>
                        <input type="checkbox" name="age90" id="age90" value="age90" checked={checkboxState.age90} onChange={handleCheckboxChange} />
                        <label For="age90">90's</label>
                    </div>                    
                </form>
            </div>
            
            <div className="notice">
                <h4>{translatedText['Details by country'] || 'Details by country'}</h4>
                <div>
                    <div className='tbwrp'>
                        <table className='detail_num'>
                            <colgroup>
                                <col width='8%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                                <col width='5%' />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>{translatedText['division'] || 'division'}</th>
                                    <th>{translatedText['january'] || 'january'}</th>
                                    <th>{translatedText['february'] || 'february'}</th>
                                    <th>{translatedText['March'] || 'March'}</th>
                                    <th>{translatedText['april'] || 'april'}</th>
                                    <th>{translatedText['May'] || 'May'}</th>
                                    <th>{translatedText['June'] || 'June'}</th>
                                    <th>{translatedText['July'] || 'July'}</th>
                                    <th>{translatedText['August'] || 'August'}</th>
                                    <th>{translatedText['September'] || 'September'}</th>
                                    <th>{translatedText['october'] || 'october'}</th>
                                    <th>{translatedText['November'] || 'November'}</th>
                                    <th>{translatedText['december'] || 'december'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rendering()}  
                            </tbody>
                        </table>
                    </div>
                    <div className='pasing paging'>
                        <PagingComponent
                            pagination = { pagination }
                            params = { params }
                            onPageChange = { movePage }
                        />
                    </div>
                </div>
            </div>                        
        </div>                          
             
        
    );
}

  
export default Nation;