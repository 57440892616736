import { Outlet,  } from 'react-router-dom';
import React, {  useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { useMenuContext } from './MenuContext';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from "react-responsive";
import Mobilemenu from './pages/mobile_menu';
import { useUrl } from './pages/external_module';


export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({
      query: "(max-width:768px)"
    });
    return <>{isMobile && children}</>
  }
  
  export const Pc = ({ children }) => {
    const isPc = useMediaQuery({
      query: "(min-width:769px)"
    });
    return <>{isPc && children}</>
  }

// left box on off 기능 // 
const Layout = ({ activeItem, mini_activeItem, handleItemClick, handleMiniItemClick }) => {
    const [translatedText, setTranslatedText] = useState({});
    const [  , setCookie ] = useCookies(['sessionID','userGrade','language']);
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);
    console.log('cookies.userGradecookies.userGrade',cookies.userGrade)
    const language_change = cookies.language
    useEffect(() => {
        if (language_change === '2') {
          const translatedText = {
            'Dashboard': 'ຈັດການຕະຫຼາດ',
            'Detailed inquiry': 'ການຮູບແບບລາຍລະອຽດ',
            'Visitor': 'ນັກທ່ອງທ່ຽວ',
            'Hotel':'ໂຮງແຮມ',
            'Data management':'ການຈັດການຂໍ້ມູນ',
            'Announcement':'ປະກາດ',
            'menu':'ເມນູ',
            'close':'ໃກ້',
            'Logout':'ອອກ​ຈາກ​ລະ​ບົບ',
            'English':'ພາສາອັງກິດ',
            'Laos':'ປະເທດລາວ'
          };
          setTranslatedText(translatedText);
        } else {
          setTranslatedText({});
        }
      }, [language_change]);
    const changeLanguage = (language) => {
        if (language === 'laos') {
            setCookie('language', 2, {path: "/"});
        } else {
            setCookie('language', 1, {path: "/"});
        }
    };

    const { login_button } = useUrl();  // 클릭시 login_url경로로 이동

    const [lst02tf, setlst02tf] = useState();

    const mini_handleItemClick = (item,main_item) => {
        handleMiniItemClick(item);
        handleItemClick(main_item);
    };
    const lst02Click = () => {
        setlst02tf(true)
    };
    const lst02noneClick = () => {
        setlst02tf(false)
    }
    useEffect(() => {
        const storedActiveItem = localStorage.getItem('activeItem');
        const stored_miniActiveItem = localStorage.getItem('mini_activeItem');
        // console.log('stored_miniActiveItem',stored_miniActiveItem)
        if(stored_miniActiveItem){
            handleMiniItemClick(stored_miniActiveItem)
        }
        if (storedActiveItem) {
            handleItemClick(storedActiveItem);
        }
        if (storedActiveItem !== 'lst02' || (storedActiveItem === 'lst02' && lst02tf === true)) {
            handleMiniItemClick(undefined);
            localStorage.setItem('mini_activeItem', undefined);
        }
    }, [handleItemClick, handleMiniItemClick,lst02tf]);
    
    const [, , removeCookies] = useCookies(['sessionID'],['superuser'],['staff']);

    const logout = () => {
        removeCookies('sessionID', { path: '/' });
        removeCookies('usergrade', { path: '/' });


         // 세션 정보 출력
        console.log("Session after logout:", cookies.sessionID);
        login_button();
    };
    const {isMenuOpen, setMenuOpen} = useMenuContext();
    // console.log("isMenuOpen",isMenuOpen)
    ///////////////////////////////////////////////////////////////////////////세션//
    

    ////////////////////////////////////////////////////////////////////////////////
    // console.log(activeItem)
    
    const left_menu_btn_on = () => {
        setMenuOpen(false);
    };
  
    const left_menu_btn_off = () => {
        setMenuOpen(true);
    };
   
  return (
    <div id="wrap">     
        <div className="contents clear_fix">
            <div className="dashboard_wrp">
                <Pc>
                    <div className="left_box">
                        <p className={`${"left_menu_btn"} ${isMenuOpen ? "active" : "blind"}`} onClick={left_menu_btn_on}>{translatedText['menu'] || 'menu'}</p>
                        <p className={`${"left_menu_btn_close"} ${isMenuOpen ? "blind" : "active"}`} onClick={left_menu_btn_off}>{translatedText['close'] || 'close'}</p>
                        <div className={`${"main_list"} ${isMenuOpen ? "blind" : "active"}`}>
                            <p className="logo"><Link to="/dashboard"><img src="/images/main_logo.gif" alt="logo" /></Link></p>
                            <div className="list_box">
                                <ul>
                                    <li className={`lst01 ${activeItem === 'lst01' ? 'on' : ''}`} onClick={() => {handleItemClick('lst01');lst02noneClick();}}>
                                    <Link to="/dashboard">{translatedText['Dashboard'] || 'Dash board'}</Link>
                                </li>

                                <li className={`lst02 ${activeItem === 'lst02' ? 'on' : ''}`} onClick={() => {handleItemClick('lst02'); lst02Click();}}>
                                    <Link to="/detail">{translatedText['Detailed inquiry'] || 'Detailed inquiry'}</Link>
                                </li>
                                    <div className='minibox'>
                                            <ul>
                                                <li className={`${mini_activeItem === 'lst_mini01' ? 'on' : ''}`}onClick={() => {mini_handleItemClick('lst_mini01','lst02');lst02noneClick();}}><Link to="/visitor">{translatedText['Visitor'] || 'Visitor'}</Link></li>
                                                <li className={`${mini_activeItem === 'lst_mini02' ? 'on' : ''}`} onClick={() => {mini_handleItemClick('lst_mini02','lst02');lst02noneClick();}}><Link to="/hotel">{translatedText['Hotel'] || 'Hotel'}</Link></li>
                                            </ul>
                                        </div>


                                    {/* {is_superuser ? ( */}
                                    {cookies.userGrade === '3' ? (
                                    <li className={`lst03 ${activeItem === 'lst03' ? 'on' : ''}`} onClick={() => {handleItemClick('lst03');lst02noneClick();}}>

                                        <Link to="/data_management">{translatedText['Data management'] || 'Data management'}</Link></li>
                                    ) : (null)}

                                    <li className={`lst04 ${activeItem === 'lst04' ? 'on' : ''}`} onClick={() => {handleItemClick('lst04');lst02noneClick();}}>
                                        <Link to="/noticelist">{translatedText['Announcement'] || 'Announcement'}</Link>
                                    </li>
                                </ul>
                            </div>                        
                        </div>
                    </div>

                </Pc>   
                <Mobile>
                        <div className='mb_menu'>
                            <Mobilemenu />
                        </div>                        
                </Mobile>             
                <div className="right_box">
                    <Pc>
                        <div className="right_top">
                            <ul className="clear_fix">
                                {/* "LogOut" link with the onClick event */}
                                <li onClick={logout}>
                                    <Link onClick={logout} >
                                        {translatedText['Logout'] || 'Logout'}
                                    </Link>
                                </li>
                                {/* <li><a href="#!">Join</a></li> */}
                            </ul>
                            <div>
                            <button className="eng_btn" onClick={() => { changeLanguage('english');  }}>{translatedText['English'] || 'English'}</button>
                            <button className="laos_btn" onClick={() => { changeLanguage('laos');  }}>{translatedText['Laos'] || 'Laos'}</button>    
                            </div>
                        </div>
                    </Pc>
                    <div className="dashboard_cont">
                    <Outlet />

                    </div>
                </div>
            </div>
        </div>
    </div>

  )
};

export default Layout;
