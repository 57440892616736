import  useConfirm  from "./useConfirm";

const ConfirmModal = () => {

    const { confirmList } = useConfirm();
  
    if (confirmList.length <= 0) return null;
    const modalContainer ={
      width: "100%",
      height: '100%',
      position: 'fixed',
      top: 0,
      left: 0,
      display: 'flex',
      'justify-content': 'center',
      background: '#00000064',

    };
    const modal_content = {
      width: '450px',
      height: '130px',
      background: '#fff',
      'border-radius': '10px',
      'box-shadow': '3px 2px 5px #7979798a',
      'position': 'relative',
      'margin-top':'5px',
    }
    const text_style = {
      'margin-top': '20px',
      'margin-left':'20px',
      'font-size': '23px',
      'font-weight' : '500',
      'color' : '#0d0d0d',
    }
    
    const btn_wrp = {
      'margin' : '40px 0 0 190px'
    }

    const button_style_yes = {
      'margin-left' : '10px',
      'font-size': '17px',
      'background' : '#0062dd',
      'border-radius': '10px',
      'width' : '120px',
      'height' : '35px',
      'line-height' : '35px',
      'color' : '#fff',


    }
    const button_style_no = {      
      'font-size': '17px',
      'background' : '#5f5f5f',
      'border-radius': '10px',
      'width' : '120px',
      'height' : '35px',
      'line-height' : '35px',
      'color' : '#fff',
    }
  
    return (
  
      <div style={modalContainer}>
        {confirmList.map(({ id, message, buttons: { ok, close, cancel } }, i) => {
          return (
            <div  style={modal_content}key={message}>
              <p style={text_style}>{message}</p>
              <div style={btn_wrp}>
                  {cancel && <button style={button_style_no}onClick={cancel.click}>{cancel.text}</button>}    
                  <button style={button_style_yes}onClick={ok.click}>{ok.text}</button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  
  
  export default ConfirmModal;