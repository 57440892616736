import React, { Component, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class High extends Component {
    
      
    render() {
        const series2 = this.props.data;
        // 숫자 1000이나 10000넘어가는경우 숫자 잘림으로 인해 도넛 두깨 조건에 따라 맞춤
        let innerSize_value;
        if (series2[0]['y']<10000){
            innerSize_value = '70%'
        }
        else if(series2[0]['y']>=10000 && series2[0]['y']<100000){
            innerSize_value = '65%'
        }
        else if(series2[0]['y']>=100000 && series2[0]['y']<1000000){
            innerSize_value = '60%'
        }
        else {
            innerSize_value = '55%'
        }
        const options = {
            credits: { enabled: false },
            chart: { height: 285, animation: false }, //높이 258 고정 최대 높이임 현재 width:600
            tooltip: {
                enabled: false // 말풍선 비활성화
            },
            title:null,
            plotOptions: {
                pie: {
                dataLabels: {
                    enabled: true,
                    // format: '{point.y}',
                    formatter: function () {
                        // 여기서 this.point.y는 데이터 포인트의 값입니다.
                        return Highcharts.numberFormat(this.point.y, 0, ',', ',');
                      },
                    distance: -19,
                    style: { fontSize: '13px', textOutline: 0 },
                },
                animation: false,
                showInLegend: true,
                },
            },
            legend: {
                layout: 'vertical',
                verticalAlign: 'middle',
                align: 'right',
                symbolHeight: 8,
                symbolRadius: 5,
                itemMarginTop: 0,
                itemMarginBottom: 10,
            },
            series: [
            {
            type: 'pie',
            innerSize: innerSize_value,       // 여기가 도넛 두깨 조정
            colors: ['#0072ff', '#ff1b7a'],
            data: series2,
            },
            ],

        }
        return (
            <Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={this.chartRef} // 차트 컴포넌트에 ref 추가
          />
      </Fragment>
        );
    }
}
export default High;