import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import Nation from "./detail_nation";
import Region from "./detail_region";

const menuList = {
  0: <Nation />,
  1: <Region />
};

function Detail({handleItemClick}) {
  useEffect(() => {
    handleItemClick('lst02')
    
  }, [handleItemClick]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
  const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);
  const [translatedText, setTranslatedText] = useState({});
  const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const language = cookies.language; 
  useEffect(() => {
    if (language === '2') {
      // if (selectedLanguage === 'laos') {
      const translatedText = {
        'COUNTRY': 'ປະເທດ',
        'REGION':'ພາກພື້ນ'
      };
      setTranslatedText(translatedText);
    } else {
      setTranslatedText({});
    }
  }, [language]);

  const [menu, setMenu] = useState(0);

  const changeMenu = (menuIndex) => {
    setMenu(menuIndex);
  };

  return (
    <div className="sub_wrp">
      <ul className="tabs">
        <li className={`${menu === 0 ? 'active' : ''}`} onClick={() => changeMenu(0)}>{translatedText['COUNTRY'] || 'COUNTRY'}</li>
        <li className={`${menu === 1 ? 'active' : ''}`} onClick={() => changeMenu(1)}>{translatedText['REGION'] || 'REGION'}</li>
      </ul>
      <div className="contentArea">
        {menuList[menu]}
      </div>
    </div>
  );
}

export default Detail;
