import React, { useEffect, useState, useCallback } from 'react';
import { Axios_get, Axios_post, Axios_delete, Axios_put,key_setting } from './external_module';
import PagingComponent from '../basic/PagingComponent';
import useConfirm from "../Confirm_Modul/useConfirm";
import { useCookies } from 'react-cookie';

function Hotel_management ()  {
    const { confirm } = useConfirm();
    const url = 'data-management/hotel';
    // const [cookies] = useCookies(['sessionID'],['superuser'],['staff']);
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    
    const [noticeData, setNoticeData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [params, setParams] = useState({ page: 1, recordSize: 10, pageSize: 5 });

    const fetchData = () => {
        Axios_get( url, params  ).then((result) => {
            if (result !== null) {


                setNoticeData(result.list);
                console.log("result.list",result.list)
                setPagination(result.pagination);
            } else {
                console.log('오류가 발생했습니다.');
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, [params.page]);

    {/*
        페이지 번호 클릭 및 해당 페이지로 이동
     */}
     const movePage = useCallback( async ( page ) => {
        setParams({ ...params, page });
    }, [params, pagination]);    

    const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(-1);
    const [editingRowIndex, setEditingRowIndex] = useState(-1);
    const [isAdding, setIsAdding] = useState(false); // Track "add" mode
    const [newRowData, setNewRowData] = useState({}); // Store new row data

    const rendering_key = (language) => {
        const html_key = [];
        if (noticeData.length > 0) {
            for (const key in noticeData[0]) {
                let key_name
                if(key === 'acc_id'){
                    if(language === '1'){
                        key_name = 'ID';
                    }
                    else{
                        key_name = 'ID'
                    }
                }
                else if(key === 'acc_name'){
                    if(language === '1'){
                        key_name = 'hotel name';
                    }
                    else{
                        key_name = 'ຊື່ໂຮງແຮມ'
                    }
                }
                
                else if(key === 'acc_biz_num'){
                    if(language === '1'){
                        key_name = 'BUSINESS NUMBER';
                    }
                    else{
                        key_name = 'ໝາຍເລກທຸລະກິດ'
                    }
                }
                else if(key === 'acc_address'){
                    if(language === '1'){
                        key_name = 'ADDRESS';
                    }
                    else{
                        key_name = 'ທີ່ຢູ່'
                    }
                }
                else if(key === 'acc_tel'){
                    if(language === '1'){
                        key_name = 'tel number';
                    }
                    else{
                        key_name = 'ເບີໂທ'
                    }
                }
                else if(key === 'acc_rooms'){
                    if(language === '1'){
                        key_name = 'rooms';
                    }
                    else{
                        key_name = 'ຈຳນວນຫ້ອງ'
                    }
                }
                else if(key === 'acc_rating'){
                    if(language === '1'){
                        key_name = 'RATING';
                    }
                    else{
                        key_name = 'ຄະແນນ'
                    }
                }
                else if(key === 'acc_devices'){
                    if(language === '1'){
                        key_name = 'devices';
                    }
                    else{
                        key_name = 'ຈໍາ​ນວນ​ຂອງ​ອຸ​ປະ​ກອນ​'
                    }
                }
                else if(key === 'region_code_id'){
                    key_name = 'REGION ID';
                    if(language === '1'){
                        key_name = 'REGION ID';
                    }
                    else{
                        key_name = 'ID ພາກພື້ນ'
                    }
                }
                else if(key === 'region_name'){
                    if(language === '1'){
                        key_name = 'region_name';
                    }
                    else{
                        key_name = 'ຊື່​ພາກ​ພື້ນ'
                    }
                }
                else{
                    key_name = key;
                }
                html_key.push(<th key={key}>{key_name}</th>);
            }
        }
        return html_key;
    };

    const handleCheckboxChange = (index) => {
        if (selectedCheckboxIndex === index) {
            setSelectedCheckboxIndex(-1);
            setEditingRowIndex(-1);
        } else {
            setSelectedCheckboxIndex(index);
            setEditingRowIndex(index);
        }
    };

    const rendering_Value = () => {
        const html_value = [];
        for (let i = 0; i < noticeData.length; i++) {
            const row = [];
            for (const key in noticeData[i]) {
                
                const value = noticeData[i][key];
                const textLength = value ? value.toString().length : 0;
                console.log("textLength : " + textLength + ' key : ' + key)
                const size = Math.max(10, textLength + 2);

                // Determine whether to render input fields or plain text based on the column key
                const cellContent = (key === 'acc_id'  || key === 'region_code_id' ||key === 'region_name')
                    ? value // Render plain text
                    : (
                        i === editingRowIndex
                            ? (
                                <input
                                    type="text"
                                    value={noticeData[i][key]}
                                    size={size}
                                    onChange={(e) => handleEditFieldChange(i, key, e.target.value)}
                                />
                            )
                            : value
                    );
    
                row.push(<td key={`${i}-${key}`}>{cellContent}</td>);
            }
    
            row.unshift(
                <td key={`checkbox-${i}`}>
                    <input
                        type="checkbox"
                        checked={selectedCheckboxIndex === i}
                        onChange={() => handleCheckboxChange(i)}
                    />
                </td>
            );
    
            html_value.push(
                <tr key={i}>
                    {row}
                </tr>
            );
        }
    
        // Add a new row when in "add" mode
        if (isAdding) { // 여긴 추가 버튼 클릭했을때 text생성
            const newRow = rendering_key().map((th, index) => {
                if (th.key === 'acc_id' ||th.key === 'region_name' ) {
                    // Render plain text for 'id', 'last_login', and 'date_joined'
                    return <td key={`new-${index}`}>{newRowData[th.key]}</td>;
                } else {
                    // Render input fields for other columns
                    return (
                        <td key={`new-${index}`}>
                            <input
                                type="text"
                                size="10"
                                value={newRowData[th.key] || ''}
                                onChange={(e) => handleNewRowFieldChange(th.key, e.target.value)}
                            />
                        </td>
                    );
                }
            });
    
            newRow.unshift(
                <td key={`new-checkbox`}>
                    <input
                        type="checkbox"
                        checked={selectedCheckboxIndex === noticeData.length}
                        onChange={() => handleCheckboxChange(noticeData.length)}
                    />
                </td>
            );
    
            html_value.push(
                <tr key={`new-row`}>
                    {newRow}
                </tr>
            );
        }
    
        return html_value;
    };

    const handleEditFieldChange = (rowIndex, key, value) => {
        const updatedRowData = { ...noticeData[rowIndex], [key]: value };
        setNoticeData((prevData) => {
            const newData = [...prevData];
            newData[rowIndex] = updatedRowData;
            return newData;
        });
    };


    /* 
        >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DELETE
    */
    const handleDeleteClick = async() => {

        // ** 아래 주석 confirm 함수를 사용하려면 반드시 있어야함
        // eslint-disable-next-line no-restricted-globals
        const confirmMessage = await confirm({
            message: translatedText['Are you sure you want to delete?'] || 'Are you sure you want to delete?',
            buttons: {
              ok: translatedText['YES'] || 'YES',
              cancel: translatedText['NO'] || 'NO',
            },
        });

        if(confirmMessage) {

            if (selectedCheckboxIndex !== -1) {
                const deletedRowData = noticeData[selectedCheckboxIndex];
                const id = deletedRowData.acc_id;
                
                Axios_delete(url, id).then((result) => {
                    
                    const updatedData = noticeData.filter((row, index) => index !== selectedCheckboxIndex);
                    setNoticeData(updatedData);
                    setSelectedCheckboxIndex(-1); // Deselect the checkbox
                    setEditingRowIndex(-1);

                }).catch((error) => {
                    console.error('Error sending DELETE request:', error);
                });
            } else {
                fetchData();
            }
        }
    };

    /* 
        >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> PUT
    */
    const handleUpdateClick = async() => {


        // ** 아래 주석 confirm 함수를 사용하려면 반드시 있어야함
        // eslint-disable-next-line no-restricted-globals
        // const confirmMessage = confirm("수정하시겠습니까?");
        const confirmMessage = await confirm({
            message: translatedText['Do you want to edit?'] || 'Do you want to edit?',
            buttons: {
              ok: translatedText['YES'] || 'YES',
              cancel: translatedText['NO'] || 'NO',
            },
        });
        if(confirmMessage) {
            
            if (editingRowIndex !== -1) {
                const editedRowData = noticeData[editingRowIndex];
                const id = editedRowData.acc_id;
    
                // Exclude 'date_joined' and 'last_login' fields
                const { date_joined, last_login, ...dataWithoutDates } = editedRowData;
    
                Axios_put( url, id, dataWithoutDates ).then((result)=> {
                    setSelectedCheckboxIndex(-1); // checkbox click false
                    setEditingRowIndex(-1);         //text box false
                }).catch((error) => {
                    console.error('Error sending PUT request:', error);
                });
            }
        }
    };
    


    /* 
        >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> POST
    */
    const handleAddClick = () => {
        setIsAdding(!isAdding); // Toggle "add" mode
    };

    const handleAddCompleteClick = () => {
        setIsAdding(false);
        console.log('newRowData',newRowData)
        Axios_post( url, newRowData ).then((result) => {
            if(result !== null) {
                console.log('POST request response:', result.data);

                // Add 완료시, 데이터 다시 가져와서 렌더링
                fetchData();
            } else{
                console.log('오류가 발생했습니다.');
            }
        }).catch((error) => {
            console.error('Error sending POST request:', error);
        });

        // Reset new row data
        setNewRowData({});
    };

    const handleNewRowFieldChange = (key, value) => {
        setNewRowData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    /* 언어변경 */
    const [translatedText, setTranslatedText] = useState({});
    const language = cookies.language
    console.log("language",language)
    useEffect(() => {
        if (language === '2') {
        const translatedText = {
            'Do you want to edit?' : 'ທ່ານຕ້ອງການແກ້ໄຂບໍ?',
            'Are you sure you want to delete?' :'ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບ?',
                'YES':'ແມ່ນແລ້ວ',
                'NO':'ບໍ່',
            'Hotel management': 'ການຄຸ້ມຄອງໂຮງແຮມ',
            'Addcomplet': 'ເພີ່ມຕື່ມ',
            'Edit':'ແກ້ໄຂ',
            'Delete':'ລຶບ',
            'Cancel': 'ຍົກເລີກ',
            'add': 'ເພີ່ມຕື່ມ2'
        };
        setTranslatedText(translatedText);
        } else {
        setTranslatedText({});
        }
    }, [language]);

    return (
        <div>
            <div className="notice">
                <h4>{translatedText['Hotel management'] || 'Hotel management'}</h4>
                <div>
                    <div className="tbwrp">
                        <table>
                            <colgroup>
                                <col width='1%' />
                                <col width='4%' />
                                <col width='15%' />
                                <col width='*' />
                                <col width='7.8%' />
                                <col width='10.9%' />
                                <col width='6.9%' />
                                <col width='5%' />
                                <col width='10.8%' />
                                <col width='6.4%' />
                                <col width='10%' />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    {rendering_key(language)}
                                </tr>
                            </thead>
                            <tbody>
                                {rendering_Value()}
                            </tbody>
                        </table>
                    </div>
                    {cookies.userGrade === '3'  ? (
                        <div className='data_btnwrp'>
                            <div>
                                <button className="txt_add" onClick={handleAddClick}>
                                    {isAdding ? (translatedText['Cancel'] || 'Cancel') : (translatedText['add'] || 'Add')}
                                </button>
                            </div>
                            {isAdding && (
                                <div>
                                    <button onClick={handleAddCompleteClick} className="txt_add combtn">{translatedText['add'] || 'Add'}</button>
                                </div>
                            )}
                            <div>
                                <button onClick={handleUpdateClick} className="txt_add editbtn">{translatedText['Edit'] || 'Edit'}</button>
                            </div>
                            <div>
                                <button onClick={handleDeleteClick} className="txt_add delbtn">{translatedText['Delete'] || 'Delete'}</button>
                            </div>
                        </div>
                    ) : (
                        <div className='data_btnwrp'>
                        
                        <div>
                            <button onClick={handleUpdateClick} className="txt_add editbtn">{translatedText['Edit'] || 'Edit'}</button>
                        </div>
                       
                    </div>
                    )}
                    <div className="pasing paging">
                        <PagingComponent
                            pagination = { pagination }
                            params = { params }
                            onPageChange = { movePage }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

  
export default Hotel_management;