import { useUrl } from './external_module';//
import  { useEffect } from 'react';
const Home = () => {
  const { login_button } = useUrl();  // 클릭시 login_url경로로 이동
  useEffect(() => {
    login_button();
  }, [login_button]);
 
  return null;
};

export default Home;