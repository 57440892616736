import React from 'react';
import { useUrl } from './external_module';

function NotFound() {
  const { back_button } = useUrl(); // 클릭시 login_url경로로 이동

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>404 Not Found</h1>
        <p style={styles.message}>죄송합니다. 요청하신 페이지를 찾을 수 없습니다.</p>
        <div>
          <button
            style={styles.button} // Apply the button style from the 'styles' object
            type="submit"
            onClick={back_button}
            title="LOGIN"
          >
            이전 페이지로 이동 하기
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(120deg, #84fab0, #8fd3f4)',
    fontFamily: 'Arial, sans-serif',
  },
  card: {
    padding: '20px',
    borderRadius: '10px',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  message: {
    fontSize: '24px',
    color: '#555',
  },
  button: {
    fontSize: '18px',
    padding: '10px 20px',
    borderRadius: '5px',
    background: '#333',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
};

export default NotFound;