import React, { useEffect, useState, useCallback } from 'react';
import { Axios_delete, Axios_get, Axios_post, Axios_put } from './external_module';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import useConfirm from "../Confirm_Modul/useConfirm";

import PagingComponent from '../basic/PagingComponent';


function Visitor_management ()  {
    const { confirm } = useConfirm();
    const url = 'data-management/visitor';
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    
    // 데이터 로드 및 페이징 관련 
    const [noticeData, setNoticeData] = useState([]);
    const [pagination, setPagination] = useState({});

    const [params, setParams] = useState({ page: 1, recordSize: 10, pageSize: 5 });

      /* 언어변경 */
      const [translatedText, setTranslatedText] = useState({});
      const language = cookies['language']
      useEffect(() => {
          if (language === '2') {
          const translatedText = {
            'Are you sure you want to delete?' :'ທ່ານແນ່ໃຈບໍ່ວ່າຕ້ອງການລຶບ?',
            'YES':'ແມ່ນແລ້ວ',
            'NO':'ບໍ່',
            'Visitor management': 'ການຄຸ້ມຄອງໂຮງແຮມ',
            'Addcomplet': 'ເພີ່ມຕື່ມ',
            'Edit':'ແກ້ໄຂ',
            'Delete':'ລຶບ',
            'Cancel': 'ຍົກເລີກ',
            'add': 'ເພີ່ມຕື່ມ2'
          };
          setTranslatedText(translatedText);
          } else {
          setTranslatedText({});
          }
      }, [language]);
    const fetchData = () => {
        Axios_get( url, params ).then((result) => {
            if (result !== null) {
                console.log("result",result);
                // 서버응답으로부터 해당 페이지의 값을 필요한 곳에 SET
                setNoticeData(result.list);
                setPagination(result.pagination);

            } else {
                console.log('오류가 발생했습니다.');
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, [params.page]);

   
     const movePage = useCallback( async ( page ) => {
        setParams({ ...params, page });
        console.log("PageNumber >> ", page);
    }, [params, pagination]);    

    const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(-1);
    const [editingRowIndex, setEditingRowIndex] = useState(-1);
    const [isAdding, setIsAdding] = useState(false); // Track "add" mode
    const [newRowData, setNewRowData] = useState({}); // Store new row data

    const rendering_key = (language) => {
        const html_key = [];

        if (noticeData.length > 0) {
            for (const key in noticeData[0]) {
                let key_name
                
                if(key === 'visitor_id'){
                    key_name = 'ID'
                }
                else if(key === 'country_name'){
                    if(language === '1'){
                        key_name = 'COUNTRY';
                    }
                    else{
                        key_name = 'ປະເທດ'
                    }
                }
                else if(key === 'photo'){
                    if(language === '1'){
                        key_name = 'photo';
                    }
                    else{
                        key_name = 'ພາບ'
                    }
                }
                else if(key === 'name'){
                    if(language === '1'){
                        key_name = 'NAME';
                    }
                    else{
                        key_name = 'ຊື່'
                    }
                }
                else if(key === 'acc_name'){
                    if(language === '1'){
                        key_name = 'hotel name';
                    }
                    else{
                        key_name = 'ຊື່ໂຮງແຮມ'
                    }
                }
                else if(key === 'birth'){
                    if(language === '1'){
                        key_name = 'birth';
                    }
                    else{
                        key_name = 'ການເກີດ'
                    }
                }
                else if(key === 'check_in'){
                    if(language === '1'){
                        key_name = 'check_in';
                    }
                    else{
                        key_name = 'ເຊັກອິນ'
                    }
                }
                else if(key === 'check_out'){
                    if(language === '1'){
                        key_name = 'check_out';
                    }
                    else{
                        key_name = 'ເຊັກເອົາ'
                    }
                }
                else if(key === 'sex'){
                    if(language === '1'){
                        key_name = 'GENDER';
                    }
                    else{
                        key_name = 'ເພດ'
                    }
                }
                else if(key === 'accompany_num'){
                    if(language === '1'){
                        key_name = 'COMPANION'
                    }
                    else{
                        key_name = 'ສະຫາຍ'
                    }
                }
                else{
                    key_name = key
                }
                html_key.push(<th key={key}>{key_name}</th>);
            }
        }
        return html_key;
    };

    const handleCheckboxChange = (index) => {
        if (selectedCheckboxIndex === index) {
            setSelectedCheckboxIndex(-1);
            setEditingRowIndex(-1);
        } else {
            setSelectedCheckboxIndex(index);
            setEditingRowIndex(index);
        }
    };

    const rendering_Value = () => {
        const html_value = [];
        for (let i = 0; i < noticeData.length; i++) {
            const row = [];
            for (const key in noticeData[i]) {
                console.log(key)
                const value = noticeData[i][key];
                console.log("value",value)
                const textLength = value.toString().length;
                const size = Math.max(10, textLength + 2);

                // Determine whether to render input fields or plain text based on the column key
                const cellContent = (key === 'visitor_id' || key === 'last_login' || key === 'date_joined' || key === 'photo')
                    ? value // Render plain text
                    : (
                        i === editingRowIndex
                            ? (
                                <input
                                    type="text"
                                    value={noticeData[i][key]}
                                    size={size}
                                    onChange={(e) => handleEditFieldChange(i, key, e.target.value)}
                                />
                            )
                            : value
                    );
    
                row.push(<td key={`${i}-${key}`}>{cellContent}</td>);
            }
    
            row.unshift(
                <td key={`checkbox-${i}`}>
                    <input
                        type="checkbox"
                        checked={selectedCheckboxIndex === i}
                        onChange={() => handleCheckboxChange(i)}
                    />
                </td>
            );
    
            html_value.push(
                <tr key={i}>
                    {row}
                </tr>
            );
        }
    
        // Add a new row when in "add" mode
        if (isAdding) {
            const newRow = rendering_key().map((th, index) => {
                if (th.key === 'visitor_id' || th.key === 'last_login' || th.key === 'date_joined') {
                    // Render plain text for 'id', 'last_login', and 'date_joined'
                    return <td key={`new-${index}`}>{newRowData[th.key]}</td>;
                } else {
                    // Render input fields for other columns
                    return (
                        <td key={`new-${index}`}>
                            <input
                                type="text"
                                size="10"
                                value={newRowData[th.key] || ''}
                                onChange={(e) => handleNewRowFieldChange(th.key, e.target.value)}
                            />
                        </td>
                    );
                }
            });
    
            newRow.unshift(
                <td key={`new-checkbox`}>
                    <input
                        type="checkbox"
                        checked={selectedCheckboxIndex === noticeData.length}
                        onChange={() => handleCheckboxChange(noticeData.length)}
                    />
                </td>
            );
    
            html_value.push(
                <tr key={`new-row`}>
                    {newRow}
                </tr>
            );
        }
        
        return html_value;
    };


    const handleAddClick = () => {
        setIsAdding(!isAdding); // Toggle "add" mode
    };

    /* 
        >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> POST
    */
    const handleAddCompleteClick = () => {
        setIsAdding(false);
        console.log("newRowData",newRowData)
        // Send a POST request to add the new row

        // User 추가 post 요청 
        Axios_post( url, newRowData ).then((result) => {
            if(result !== null) {
                console.log('POST request response:', result.data);

                // Add 완료시, 데이터 다시 가져와서 렌더링
                fetchData();
            } else{
                console.log('오류가 발생했습니다.');
            }
        }).catch((error) => {
            console.error('Error sending POST request:', error);
        });

        // Reset new row data
        setNewRowData({});
    };

    const handleNewRowFieldChange = (key, value) => {
        setNewRowData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    
    const handleEditFieldChange = (rowIndex, key, value) => {
        const updatedRowData = { ...noticeData[rowIndex], [key]: value };
        setNoticeData((prevData) => {
            const newData = [...prevData];
            newData[rowIndex] = updatedRowData;
            return newData;
        });
    };



    /* 
        >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> DELETE
    */
    const handleDeleteClick = async() => {
        if (selectedCheckboxIndex !== -1) {
            const deletedRowData = noticeData[selectedCheckboxIndex];
            const id = deletedRowData.visitor_id;
        
            // ** 아래 주석 confirm 함수를 사용하려면 반드시 있어야함
            // eslint-disable-next-line no-restricted-globals
            // const confirmMessage = confirm("삭제하시겠습니까?");

            const confirmMessage = await confirm({
                message: translatedText['Are you sure you want to delete?'] || 'Are you sure you want to delete?',
                buttons: {
                  ok: translatedText['YES'] || 'YES',
                  cancel: translatedText['NO'] || 'NO',
                },
            });
              

                
            if(confirmMessage) {
                Axios_delete(url, id).then((result) => {
                        
                    const updatedData = noticeData.filter((row, index) => index !== selectedCheckboxIndex);
                    setNoticeData(updatedData);
                    setSelectedCheckboxIndex(-1); // Deselect the checkbox
                    setEditingRowIndex(-1);

                }).catch((error) => {
                    console.error('Error sending DELETE request:', error);
                });
            } else {
                fetchData();
            }
        }
    };

   /* 
        >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> PUT
    */
    const handleUpdateClick = () => {

        // ** 아래 주석 confirm 함수를 사용하려면 반드시 있어야함
        // eslint-disable-next-line no-restricted-globals
        const confirmMessage = confirm("수정하시겠습니까?");
        if(confirmMessage) {
            if (editingRowIndex !== -1) {
                const editedRowData = noticeData[editingRowIndex];
                const id = editedRowData.id;

                // Exclude 'date_joined' and 'last_login' fields
                const { date_joined, last_login, ...dataWithoutDates } = editedRowData;
                console.log('dataWithoutDates',dataWithoutDates)
                Axios_put( url, id, dataWithoutDates ).then((result)=> {
                    setSelectedCheckboxIndex(-1); // Deselect the checkbox

                    setEditingRowIndex(-1);
                }).catch((error) => {
                    console.error('Error sending PUT request:', error);
                });     
            }
        }
    };


    return (
        <div>
            <div className="notice">
                <h4>{translatedText['Visitor management'] || 'Visitor management'}</h4>
                <div>
                    <div className="tbwrp tbwrp03">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    {rendering_key(language)}
                                </tr>
                            </thead>
                            <tbody>
                                {rendering_Value()}
                            </tbody>
                        </table>
                    </div>
                    {cookies.userGrade === '3' ? (

                    <div className='data_btnwrp'>
                        {/* <div>
                            <button className="txt_add" onClick={handleAddClick}>
                                {isAdding ? (translatedText['Cancel'] || 'Cancel') : (translatedText['add'] || 'Add')}
                            </button>
                        </div>
                        {isAdding && (
                            <div>
                                <button onClick={handleAddCompleteClick} className="txt_add combtn">{translatedText['add'] || 'Add'}</button>
                            </div>
                        )}
                        <div>
                            <button onClick={handleUpdateClick} className="txt_add editbtn">{translatedText['Edit'] || 'Edit'}</button>
                        </div> */}
                        <div>
                            <button onClick={handleDeleteClick} className="txt_add delbtn">{translatedText['Delete'] || 'Delete'}</button>
                        </div>
                    </div>
                    ) : (
                        <div className='data_btnwrp'>
                        
                        {/* <div>
                            <button onClick={handleUpdateClick} className="txt_add editbtn">{translatedText['Edit'] || 'Edit'}</button>
                        </div> */}
                       
                    </div>
                    )}

                    <div className="pasing">
                        <div className='paging'>
                            <PagingComponent
                                pagination = { pagination }
                                params = { params }
                                onPageChange = { movePage }
                            /> 
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
}

  
export default Visitor_management;