import React, { useState,useEffect } from 'react';
import './main.css';
import './base.css';
import Dashboard from './pages/dashboard';
import Login from './pages/login';
import Notice from './pages/notice';
import NewNotice from './component/NewNoticeForm';
import Noticelist from './pages/noticelist';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Datamanage from './pages/data_management';
import Detail from './pages/detail';
import HotelDetail from './pages/hotel_detail';
import Visitor from './pages/visitor';
import Hotel from './pages/hotel';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
// import { BrowserRouter as Router, Route, Routes, Navigate, Switch } from 'react-router-dom';
import Layout from './Layout';
import { MenuProvider } from './MenuContext';
import { useCookies } from "react-cookie";

import ConfirmContextProvider from './Confirm_Modul/ConfirmContextProvider'
import ConfirmModal from './Confirm_Modul/ConfirmModal'
function App() {
  const [cookies] = useCookies(['sessionID']);
  const [activeItem, setActiveItem] = useState();
  const [mini_activeItem, setMiniActiveItem] = useState();
  // const location = useLocation(); // useLocation 훅을 통해 현재 URL 정보 가져오기

    useEffect(() => {
      // const { location } = this.props;
      // console.log("location.pathname",location.pathname)
        // 로컬 스토리지에서 activeItem 값 불러오기
        const storedActiveItem = localStorage.getItem('activeItem');
        const storedMiniActiveItem = localStorage.getItem('mini_activeItem');
        if (storedActiveItem) {
            setActiveItem(storedActiveItem);
        }
        if (storedMiniActiveItem) {
          setMiniActiveItem(storedMiniActiveItem);
        }
    }, []);
    const handleMiniItemClick = (item) => {
      setMiniActiveItem(item);
      // Save mini_activeItem to local storage
      localStorage.setItem('mini_activeItem', item);
    };
    const handleItemClick = (item) => {
      
        setActiveItem(item);
        // 로컬 스토리지에 activeItem 값 저장
        localStorage.setItem('activeItem', item);
    };
    const isSessionValid = !!cookies.sessionID;

  return (
    <BrowserRouter>
      <ConfirmContextProvider>
        <MenuProvider>
          <Routes>
          {isSessionValid ? (
              <Route path="/" element={<Layout activeItem={activeItem}  mini_activeItem={mini_activeItem} handleItemClick={handleItemClick} handleMiniItemClick={handleMiniItemClick}/>}> 
                <Route index element={<Home />} />    {/*무조건 login 페이지로 가게 이동 */}
                <Route path="/dashboard" element={<Dashboard handleItemClick={handleItemClick}/>} />
                <Route exact path="/notice/:id" element={<Notice />} />
                <Route exact path="/hotel_detail/:id" element={<HotelDetail />} />
                <Route path="/notice" element={<NewNotice />} />
                <Route path="/noticelist" element={<Noticelist handleItemClick={handleItemClick}/>} />
                <Route path="/data_management" element={<Datamanage handleItemClick={handleItemClick}/>} />
                <Route path="/detail" element={<Detail handleItemClick={handleItemClick}/>} />
                <Route path="/visitor" element={<Visitor />} />
                <Route path="/hotel" element={<Hotel />} />
              </Route>
              
            ) : (
            // 세션이 유효하지 않을 경우 로그인 페이지로 리다이렉트
            <Route path="/" element={<Navigate to="/login" />} />
          )}
              <Route path="*" element={<NotFound />} />     {/* 존재하지 않는 페이지 들어갈경우 로그인 페이지로 이동하게 진행했음 */}
              <Route path="/login" element={<Login />} />
          </Routes>     
          <ConfirmModal />
        </MenuProvider>
      </ConfirmContextProvider>
    </BrowserRouter>
  );
}
export default App;
