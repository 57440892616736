import React, { useState, useEffect, useInsertionEffect } from 'react';
import { useUrl, Axios_post } from '../pages/external_module';
import { useLocation } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import File from '../basic/file_Custom';
function NewNotice() {

    const { noticelist_button } = useUrl();  // 클릭시 login_url경로로 이동
    const [title, setTitle] = useState('');  
    const [content, setContent] = useState('');
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);

    const sessionID = cookies["sessionID"];    
    const [selectedOption, setSelectedOption] = useState(1);
    const [selectedFile, setSelectedFile] = useState(null); // Declare selectedFile state variable
    const language = cookies.language; 
    const commit_check = () => {      // Complete 버튼 클릭 이벤트 발생시 데이터 서버로 post 전송
        const url = 'web/notice'
        //////////////////////// 현재 시간 부분 new Date할땐 들어가는데 한국 형식으로 바꾸면 str으로 되어서 에러 발생 이거 수정 방안 찾아야함
        const now_time = new Date();    
        const now_time_koria = now_time.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
        const formData = new FormData();

        formData.append("file", selectedFile);
        for(const key of formData.keys()){
            console.log(key)
        }
        console.log('selectedFile클릭이벤트발생시',selectedFile);

        console.log("formData",formData);
        const context = {
            "title": title,
            "content": content,
            "view_count": 0,
            "category_id_id": selectedOption,
            "user_id_id" : sessionID,
        };
        const a = JSON.stringify(context)
        console.log('a',a)
        formData.append('test', JSON.stringify(context))
        console.log("!! ", formData);
        Axios_post(url,formData).then((result) => {
            if (result !== null) {
                noticelist_button();
            } else {console.log('오류가 발생했습니다.');}
        })
    };


    // // const navigate = useNavigate(); // 이건 다른 URL로 이동할때 쓰임
    // ///////////////////////////// 상태 갱신 필요한 input_type이 text인 데이터들 작성 란
    const handleFileChange = (file_data) => {
        console.log('file_data',file_data)
            setSelectedFile(file_data); // Update selectedFile state with the selected file
    };
    const handleOptionChange = (e) => {
      setSelectedOption(Number(e.target.value));
    };

    /* 언어변경 */
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const selectedLanguage = searchParams.get('lang') || 'english'; // Default to English
    const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);
    const [translatedText, setTranslatedText] = useState({});

    useEffect(() => {
        if (language === '2') {
        const translatedText = {
            "Please enter the subject.":'ກະລຸນາໃສ່ຫົວຂໍ້.',
            "Please enter your details.":'ກະລຸນາໃສ່ລາຍລະອຽດຂອງທ່ານ.',
            'DEVICE NOTICE': 'ແຈ້ງການອຸປະກອນ',
            'ADMIN NOTICE':'ແຈ້ງການຜູ້ບໍລິຫານ',
            'POST':'ໂພສ',
            'TITLE':'ຫົວຂໍ້',
            'cancel':'ຍົກເລີກ',
            'complete':'ສໍາເລັດ',
            'Download':'ດາວໂຫຼດ',
            'FILE':'ໄຟລ໌',
            'File':'ໄຟລ໌',
            'Notice writing':'ຂຽນແຈ້ງການ',
            'Attachment':'ໄຟລ໌ແນບ'
        };
        setTranslatedText(translatedText);
        } else {
        setTranslatedText({});
        }
    }, [language]);

    return(
        <div>     
            <div className="sub_wrp notice_wrp">

                <h4>{translatedText['Notice writing'] || 'Notice writing'}</h4>
                <p className='select_box'>
                    <select name="board_list" id="board_list" onChange={handleOptionChange} value={selectedOption}>
                        <option value={1}>{translatedText['DEVICE NOTICE'] || 'DEVICE NOTICE'}</option>
                        <option value={2}>{translatedText['ADMIN NOTICE'] || 'ADMIN NOTICE'}</option>
                        <option value={3}>{translatedText['POST'] || 'POST'}</option>
                    </select>
                </p>
                <div className='title'>
                <h5>{translatedText['TITLE'] || 'TITLE'}</h5>
                    <p><input type="text" name="" id="title"  title="title" placeholder={translatedText["Please enter the subject."] || "Please enter the subject."} value={title} onChange={(e) => setTitle(e.target.value)} /></p>
                </div>
                <p className='textarea_box'>
                    <textarea name="" id="" cols="30" rows="10" value={content} onChange={(e) => setContent(e.target.value)} placeholder={translatedText["Please enter your details."] || "Please enter your details."}></textarea>
                </p>
                <div className='data'>
                    <p className='tit'>{translatedText['File'] || 'File'}</p>  
                    {/* <input type="file" name="" id="file"  onChange={handleFileChange} title="file" /> */}
                    <File onFileChange={handleFileChange}/>
                </div>
                <div className='btnwrp'>
                    <button type="" onClick={commit_check} title="complete" className='col01'>{translatedText['complete'] || 'complete'}</button>
                    <button type="submit" onClick={noticelist_button} title="cancel" className='col02'>{translatedText['cancel'] || 'cancel'}</button>
                </div>

            </div>                        
        </div>                          
                

    );
}

export default NewNotice;