import React, {useState} from 'react';
import { Link } from 'react-router-dom';

function Mobilemenu () {
    const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
    const [menu_class, setMenuClass] = useState("menu hide")
    const [mbg, setMgClass] = useState("mb hide")
    const [isMenuClicked, setIsmenuClicked] = useState(false)

    const updateMenu = () => {
        if(!isMenuClicked) {
            setBurgerClass("burger-bar clicked")
            setMenuClass("menu visible")
            setMgClass("mbg visible")
        }
        else {
            setBurgerClass("burger-bar unclicked")
            setMenuClass("menu hide")
            setMgClass("mbg hide")
        }
        setIsmenuClicked(!isMenuClicked)
    }

    return(
       <div>
            <nav className='mb_nav'>
                <p><img src="images/logo_m.png" alt="" /></p>
                <div className='burger-menu' onClick={updateMenu}>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                    <div className={burger_class}></div>
                </div>
            </nav>
            <div className={menu_class}>
                <ul>
                    <li className='mbl01'><Link to="/dashboard">Dash board</Link></li>
                    <li className='mbl02'><Link to="/detail">Detailed inquiry</Link>
                        <div>
                            <ul>
                                <li><Link to="/visitor">Visitor</Link></li>
                                <li><Link to="/hotel">Hotel</Link></li>
                            </ul>
                        </div>
                    </li>
                    <li className='mbl03'><Link to="/data_management">Data management</Link></li>
                    <li className='mbl04'><Link to="/noticelist">Announcement</Link></li>
                </ul>            
            </div>
            <div className={mbg}></div>

       </div>
    );
 };
  
  export default Mobilemenu;