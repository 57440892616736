// MenuContext.js
import React, { createContext, useState, useContext } from 'react';

const MenuContext = createContext();

export function MenuProvider({ children }) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <MenuContext.Provider value={{ isMenuOpen, setMenuOpen }}>
      {children}
    </MenuContext.Provider>
  );
}

export function useMenuContext() {
  return useContext(MenuContext);
}