import React, { useEffect, useState, useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { Axios_get } from '../pages/external_module';
import { useParams, useNavigate  } from 'react-router-dom';
function HotelDetail ()  {
    const navigate = useNavigate();

    const handleGoBack = () => {
        // 이전 페이지로 이동
        navigate(-1);
    };
    const { id } = useParams();    
    console.log("ids",id)
    const url = `web/detail/hotel/${id}`;
    console.log("id > ", id);
    const [hotelDetailData, setHotelDetailData] = useState(null);
    const [monthData, setMonthData] = useState({}); // 월별 데이터 상태 추가
    const [ageData, setAgeData] = useState({}); // 연령대 데이터 상태 추가
    const [nationData, setNationData] = useState({}); // 국가 데이터 상태 추가
    const [translatedText, setTranslatedText] = useState({});
    const [ cookies ] = useCookies(['sessionID','userGrade', 'language']);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Axios_get(url);
                setHotelDetailData(result[0]);
                console.log("result",result)
                const processedMonthData = {};
                const processedAgeData = {};
                const processedNationData = {};

                 // 월별 데이터 구조화
                const monthData = {};
                result.forEach(data => {
                    const month = data.month;
                    monthData[month] = data;
                    processedMonthData[month] = data;
                    processedAgeData[month] = data.ageData;
                    processedNationData[month] = data.nationData;
                });
                setMonthData(monthData);
                setAgeData(processedAgeData);
                setNationData(processedNationData);
                console.log("Month Data > ", monthData); // 월별 데이터 확인

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [url]);
    
    const language = cookies.language; 
    // if(language === '1'){
    //     country_keys = ['','','','',,'','Germany','Spain','','','','']
    //   }
    //   else if(language === '2'){
    //     country_keys = ['','','','',,'','ເຢຍລະມັນ','ສະເປນ','','','','']
    //   }
    // ['ARG', 'AUS', 'BRA', 'CAN', 'DEU', 'ESP', 'GHA', 'KOR', 'LAO', 'NGA', 'NZL', 'USA']
    
    useEffect(() => {        
        console.log('여기 안탐?')
        console.log(language)
        if (language === '2') {
            console.log('여기 안들어옴?')
            const translatedText = {
                'Go to Previous Page':'ໄປທີ່ໜ້າກ່ອນໜ້າ',
                'Please enter your search term' : 'ກະລຸນາໃສ່ຄໍາຄົ້ນຫາຂອງທ່ານ',
                'ALL' : 'ໃຜໆກໍ',
                'Details by country': 'ລາຍລະອຽດຕາມປະເທດ',
                'AGE':'ອາຍຸ',
                'GENDER':'ເພດ',
                'december':'ທັນວາ',
                'Search': 'ຊອກຫາ',
                'SELECT A REGION' : 'ເລືອກພາກພື້ນ',
                'November':'ພະຈິກ',
                'october':'ຕຸລາ',
                'September':'ກັນຍາ',
                'August':'ສິງຫາ',
                'July':'ກໍລະກົດ',
                'June':'ມິຖຸນາ',
                'May':'ພຶດສະພາ',
                'april':'ເມສາ',
                'March':'ມີນາ',
                'february':'ກຸມພາ',
                'january':'ມັງກອນ',
                'division':'ພະແນກ',
                'NATION':'ປະເທດ',
                'Name' : 'ຊື່',
                'Address' : 'ທີ່ຢູ່',
                'Number' : 'ຕົວເລກ',
                'Rooms' : 'ຈຳນວນຫ້ອງ',
                'Class' : 'ຄະແນນ',
                'male' : 'ຊາຍ',
                'female' : 'ສະຕຣີ',
            };
            setTranslatedText(translatedText);
            
        } else {
            setTranslatedText({});
        }
    }, [language]);
    // gender
    const renderGenderData = () => {
        const months = Array.from({ length: 12 }, (_, index) => String(index + 1).padStart(2, '0'));    
        return (
            <React.Fragment>
                <tr>
                    <th rowSpan="2">{translatedText['GENDER'] || 'GENDER'}</th>
                    <th>{translatedText['male'] || 'male'}</th>
                    {months.map(month => {
                        const maleCount = monthData[month]?.male || '0';
                        return <td key={month}>{maleCount}</td>;
                    })}
                </tr>
                <tr>
                    <th>{translatedText['female'] || 'female'}</th>
                    {months.map(month => {
                        const femaleCount = monthData[month]?.female || '0';
                        return <td key={month}>{femaleCount}</td>;
                    })}
                </tr>
            </React.Fragment>
        );
    };

    const renderAgeData = () => {
        const months = Array.from({ length: 12 }, (_, index) => String(index + 1).padStart(2, '0'));
        const ageGroups = ['10s', '20s', '30s', '40s', '50s', '60s', '70s'];
    
        return (
            <React.Fragment>
                <tr>
                    <th rowSpan="8">{translatedText['AGE'] || 'AGE'}</th>
                </tr>
                {ageGroups.map(ageGroup => (
                    <tr key={ageGroup}>
                        <th>{ageGroup}</th>
                        {months.map(month => {
                            const monthDataObj = monthData[month] || {}; 
                            const ageCount = monthDataObj[ageGroup] || '0'; 
                            return <td key={month}>{ageCount}</td>;
                        })}
                    </tr>
                ))}
            </React.Fragment>
        );
    };
    
    const renderNationData = (language) => {
        const months = Array.from({ length: 12 }, (_, index) => String(index + 1).padStart(2, '0'));
        let nationCodes = ['ARG', 'AUS', 'BRA', 'CAN', 'DEU', 'ESP', 'GHA', 'KOR', 'LAO', 'NGA', 'NZL', 'USA'];
        if(language === '2'){
            nationCodes = ['ອາເຈນຕິນາ','ອອສເຕຣເລຍ','ບຣາຊິນ','ການາດາ','ເຢຍລະມັນ','ສະເປນ','ການາ','ເກົາຫຼີ','ປະເທດລາວ','ໄນຈີເຣຍ','ນິວ​ຊີ​ແລນ','nອາ​ເມລິ​ກາ']
        }
        
        return (
            <React.Fragment>
                <tr>
                    <th rowSpan="13">{translatedText['NATION'] || 'NATION'}</th>
                </tr>
                {nationCodes.map(nationCode => (
                    <tr key={nationCode}>
                        <th>{nationCode}</th>
                        {months.map(month => {
                            const monthDataObj = monthData[month] || {}; 
                            const nationCount = monthDataObj[nationCode] || '0';
                            return <td key={month}>{nationCount}</td>;
                        })}
                    </tr>
                ))}
            </React.Fragment>
        );
    }; 
    

    return (

        <div className="sub_wrp">
            {hotelDetailData && (
                <div className='hotel_detail_top'>
                    <h5> <button onClick={handleGoBack} className='pre_btn'>{translatedText['Go to Previous Page'] || 'Go to Previous Page'}</button><span className='hd_tit'>{hotelDetailData.name}</span><span className='hd_add'>{hotelDetailData.address}</span></h5>
                    <div className='hoteldetailtxt'>
                        <ul>
                            <li><span>{translatedText['Name'] || 'Name'} : </span>　　{hotelDetailData.name}</li>
                            <li><span>{translatedText['Address'] || 'Address'} : </span>　　{hotelDetailData.address}</li>
                            <li><span>{translatedText['Number'] || 'Number'} : </span>　　{hotelDetailData.number}</li>
                            <li><span>{translatedText['Rooms'] || 'Rooms'} : </span>　　{hotelDetailData.rooms} rooms</li>
                            <li><span>{translatedText['Class'] || 'Class'} :</span>　　{hotelDetailData.rating} star hotel</li>
                        </ul>
                    </div>
                </div>
            )}
            <div className='tbwrp hoteldetail_tb'>
                <table>
                    <colgroup>
                        <col width='4%' />
                        <col width='6%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                        <col width='5%' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th colSpan="2"></th>
                            <th>{translatedText['january'] || 'JAN'}</th>
                            <th>{translatedText['february'] || 'FEB'}</th>
                            <th>{translatedText['March'] || 'MAR'}</th>
                            <th>{translatedText['april'] || 'APR'}</th>
                            <th>{translatedText['May'] || 'May'}</th>
                            <th>{translatedText['June'] || 'Jun'}</th>
                            <th>{translatedText['July'] || 'Jul'}</th>
                            <th>{translatedText['August'] || 'Aug'}</th>
                            <th>{translatedText['September'] || 'Sep'}</th>
                            <th>{translatedText['october'] || 'oct'}</th>
                            <th>{translatedText['November'] || 'Nov'}</th>
                            <th>{translatedText['december'] || 'dec'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderGenderData()}
                        {renderAgeData()}
                        {renderNationData(language)}
                    </tbody>
                </table>
            </div>
        </div>
        
    );
}

export default HotelDetail;