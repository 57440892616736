import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";

const MyComponent = () => {
  const [cookies] = useCookies(["sessionID", "userGrade", "language"]);

  // Call the key_setting function and pass the cookies as needed
  const keySettings = key_setting(cookies);

  // ... rest of the component's code
};

const lang = document.cookie.split("; ")[0];

/////////////////////////////////////////////////////////////////////////데이터 이동 부분 모듈화
const instance = axios.create({
  /*
    baseURL 설정
    내부개발용과 Docker 배포용으로 구분
    - 내부(김규민 Server) : 192.168.123.111:8080
    - 내부(3층 Server) : 192.168.203.50:48080
    - 외부(3층) : 118.40.116.16:48080

    ** 추후 Proxy 서버 구축 이후, localhost로 변경 예정
  */
  // baseURL: 'http://192.168.123.111:8081/',
  // baseURL: 'http://192.168.203.50:48080',
  // baseURL: 'http://localhost:48080',
  baseURL: "https://laosapi.smsoft.co.kr",

  /*
   CORS Error 처리를 위한 요청헤더 추가
   모든 Axios 요청에 적용
  */
  headers: {
    common: { mode: "cors", "custom-header": 2 },
  },
});
export const baseURL = instance.defaults.baseURL;
// ============================== POST
export const Axios_post = (url, context) => {
  return instance
    .post(url, context)
    .then((response) => {
      //console.log('success');
      return response.data;
    })
    .catch((error) => {
      console.log("Request FAILED:", error);
      return null; // 오류가 발생했을 때 null로 취급 할거
    });
};
// ============================== GET
export const Axios_get = (url, params) => {
  return instance
    .get(url, { params })
    .then((response) => {
      //console.log('success');
      return response.data;
    })
    .catch((error) => {
      console.log("Request FAILED:", error);
      return null; // 오류가 발생했을 때 null로 취급 할거
    });
};
// ============================== DELETE
export const Axios_delete = (url, id) => {
  const del_url = url + "/" + `${id}`;
  return instance
    .delete(del_url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error sending DELETE request:", error);
    });
};
// ============================== PUT
export const Axios_put = (url, id, context) => {
  const edit_url = url + "/" + `${id}`;
  return instance
    .put(edit_url, context)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log("Request FAILED:", error);
      return null; // 오류가 발생했을 때 null로 취급 할거
    });
};
// ============================== GET for Dashboard
export const Axios_get_dashboard = (url) => {
  return instance
    .get(url, { params: {} })
    .then((response) => {
      //console.log('success');
      return response.data;
    })
    .catch((error) => {
      console.log("Request FAILED:", error);
      return null; // 오류가 발생했을 때 null로 취급 할거
    });
};
/////////////////////////////////////////////////////////////////////////버튼 클릭시 이동 하는 url 등록 모듈
export const useUrl = () => {
  const navigate = useNavigate();
  // 라우터에 등록되어있는 url만 등록하세욧
  const login_button = () => {
    // login페이지로 이동
    navigate("/login");
  };

  const back_button = () => {
    //뒤로가기
    navigate(-1);
  };
  const dashboard_button = () => {
    //dashboard로 이동
    navigate("/dashboard");
  };
  const noticelist_button = () => {
    //게시글 페이지로 이동
    navigate("/noticelist");
  };
  const visitor_detail_option_button = () => {
    //게시글 페이지로 이동
    navigate("/visitor_detail_option");
  };
  return {
    login_button,
    back_button,
    noticelist_button,
    dashboard_button,
    visitor_detail_option_button,
  };
};
export const key_setting = (cookies) => {
  const language = cookies?.language;
  const region_key_setting = () => {
    let region_keys = [
      "Phongsali",
      "LouangNamtha",
      "Bokeo",
      "Oudomxai",
      "Sainyabuli",
      "LouangPrabang",
      "Houaphan",
      "Xianghoang",
      "Vientiane",
      "Xaysomboun",
      "VientianPrefecture",
      "Bolikhamxai",
      "Khammouan",
      "Savannakhet",
      "Salavan",
      "Champasak",
      "Sekong",
      "Attapu",
    ];
    if (language === "1") {
      region_keys = [
        "Phongsali",
        "LouangNamtha",
        "Bokeo",
        "Oudomxai",
        "Sainyabuli",
        "LouangPrabang",
        "Houaphan",
        "Xianghoang",
        "Vientiane",
        "Xaysomboun",
        "VientianPrefecture",
        "Bolikhamxai",
        "Khammouan",
        "Savannakhet",
        "Salavan",
        "Champasak",
        "Sekong",
        "Attapu",
      ];
    } else if (language === "2") {
      region_keys = [
        "ຜົ້ງສາລີ",
        "ຫຼວງນ້ຳທາ",
        "ບໍ່ແກ້ວ",
        "ອຸດົມໄຊ",
        "ໄຊຍາບູລີ",
        "ຫຼວງ​ພະ​ບາງ",
        "nຫົວພັນ",
        "ຊຽງຮ່ອນ",
        "ວຽງຈັນ",
        "ໄຊສົມບູນ",
        "ນະຄອນຫຼວງວຽງຈັນ",
        "ບໍລິຄຳໄຊ",
        "ຄຳມ່ວນ",
        "ສະຫວັນນະເຂດ",
        "ສາລະວັນ",
        "ຈໍາປາສັກ",
        "ເຊກອງ",
        "nອັດຕະປື",
      ];
    }
    return region_keys;
  };
  const country_key_setting = () => {
    let country_keys = [
      "Ghana",
      "Nigeria",
      "Korea",
      "Laos",
      "NewZealand",
      "Australia",
      "Germany",
      "Spain",
      "USA",
      "Canada",
      "Brazil",
      "Argentina",
    ];
    if (language === "1") {
      country_keys = [
        "Ghana",
        "Nigeria",
        "Korea",
        "Laos",
        "NewZealand",
        "Australia",
        "Germany",
        "Spain",
        "USA",
        "Canada",
        "Brazil",
        "Argentina",
      ];
    } else if (language === "2") {
      country_keys = [
        "ການາ",
        "ໄນຈີເຣຍ",
        "ເກົາຫຼີ",
        "ປະເທດລາວ",
        "ນິວ​ຊີ​ແລນ",
        "ອອສເຕຣເລຍ",
        "ເຢຍລະມັນ",
        "ສະເປນ",
        "nອາ​ເມລິ​ກາ",
        "ການາດາ",
        "ບຣາຊິນ",
        "ອາເຈນຕິນາ",
      ];
    }
    return country_keys;
  };
  const continent_key_setting = () => {
    let continent_keys = [
      "Africa",
      "Antarctica",
      "Asia",
      "Oceania",
      "Europe",
      "North America",
      "South America",
    ];
    if (language === "1") {
      continent_keys = [
        "Africa",
        "Antarctica",
        "Asia",
        "Oceania",
        "Europe",
        "North America",
        "South America",
      ];
    } else if (language === "2") {
      continent_keys = [
        "ອາຟຣິກາ",
        "ແອນຕາກຕິກ",
        "ອາຊີ",
        "ໂອເຊຍເນຍ",
        "ເອີຣົບ",
        "ອາ​ເມລິ​ກາ​ເຫນືອ",
        "ອາ​ເມລິ​ກາ​ໃຕ້",
      ];
    }
    return continent_keys;
  };
  const language_change = () => {
    let translatedText;
    if (language === "1") {
      translatedText = {};
    } else if (language === "2") {
      translatedText = {
        //현재 HOTEL MANAGEMENT쪽 만 셋팅 해둠
        "Please enter your search term": "ກະລຸນາໃສ່ຄໍາຄົ້ນຫາຂອງທ່ານ",
        ALL: "ໃຜໆກໍ",
        "Details by country": "ລາຍລະອຽດຕາມປະເທດ",
        AGE: "ອາຍຸ",
        GENDER: "ເພດ",
        december: "ທັນວາ",
        Search: "ຊອກຫາ",
        "SELECT A REGION": "ເລືອກພາກພື້ນ",
        November: "ພະຈິກ",
        october: "ຕຸລາ",
        September: "ກັນຍາ",
        August: "ສິງຫາ",
        July: "ກໍລະກົດ",
        June: "ມິຖຸນາ",
        May: "ພຶດສະພາ",
        april: "ເມສາ",
        March: "ມີນາ",
        february: "ກຸມພາ",
        january: "ມັງກອນ",
        division: "ພະແນກ",
        NATION: "ປະເທດ",
        Name: "ຊື່",
        Address: "ທີ່ຢູ່",
        Number: "ຕົວເລກ",
        Rooms: "ຈຳນວນຫ້ອງ",
        Class: "ຄະແນນ",
        male: "ຊາຍ",
        female: "ສະຕຣີ",
        "Hotel management": "ການຄຸ້ມຄອງໂຮງແຮມ",
        Addcomplet: "ເພີ່ມຕື່ມ",
        Edit: "ແກ້ໄຂ",
        Delete: "ລຶບ",
        Cancel: "ຍົກເລີກ",
        add: "ເພີ່ມຕື່ມ2",
      };
      return translatedText;
    }
  };
  return {
    region_key_setting,
    country_key_setting,
    continent_key_setting,
    language_change,
  };
};
