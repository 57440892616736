import React from 'react';
import { Oval } from 'react-loader-spinner';

function Loading() {
    return (
        <div style={{ margin:'100px' }}>
  <Oval className="loading-spinner" color="#ff0000" height={150} width={150} />
        </div>
    );
  }
  

export default Loading;
